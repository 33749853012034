/*
 * 업무구분: 영업
 * 화 면 명: MSPFS303M
 * 화면설명: 연금계좌신규개설 1011
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="연금계좌신규개설" :topButton="true">
    <fs-header 
      ref="fsHeader" 
      :propObj="pHeaderObj">
      </fs-header>

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left w80p mr0 gap30">
                <div class="wrap-input row">
                  <label class="emphasis"> 고객번호 </label>
                  <mo-decimal-field 
                    numeric
                    v-model="lv_ed_cust_no.value" 
                    :class="lv_ed_cust_no.error ? 'error' : ''"
                    ref="custNo" 
                    class="w150" 
                    type="number" 
                    mask="#######"
                    @keyup="fn_SearchValid($event, 0, true)"
                  />
                  <mo-button :disabled="Btn_cust_search" @click="fn_popup308"> 고객정보 </mo-button>
                  <mo-text-field v-model="lv_ed_cust_nm.value" placeholder="입력하세요" class="w130" disabled />
                </div>
                <div class="wrap-input row ml54">
                  <label> 투자성향 </label>
                  <mo-dropdown 
                    :items="lv_commCode.code269" 
                    v-model="cust_grade" 
                    placeholder="선택하세요" 
                    class="w150" 
                    :disabled="cust_grade_disabled" 
                  />
                </div>
              </div>
              <div class="right w20p"></div>
            </div>
            <div class="row w100p">
              <div class="left w80p mr0 gap40">
                <div class="wrap-input row">
                  <label> 신규구분 </label>
                  <mo-radio-wrapper :items="Rad_proc_gb" v-model="proc_gb" @input="fn_proc_gb_onitemchanged" class="row"/>
                </div>
                <div class="wrap-input row">
                  <label class="emphasis"> 대리인여부 </label>
                  <mo-radio-wrapper 
                    :items="rdo_agnt_chk_yn" 
                    v-model="agnt_chk_yn.value" 
                    :class="agnt_chk_yn.error ? 'error' : ''"
                    @input="fn_agntSelect" 
                    class="row"
                  />
                </div>
                <div class="wrap-input row">
                  <label> 동의기간 </label>
                  <p class="input-txt">{{std_dt}} ~ {{end_dt}}</p>
                </div>
              </div>
              <div class="right w20p">
                <div class="wrap-button row">
                  <mo-button @click="fn_init"> 초기화 </mo-button>
                  <mo-button primary @click="fn_SearchValid($event, 0, true)" ref="btn_search"> 조회 </mo-button> <!-- 문구변경 (조회기록 > 조회) 2023-04-07 jy -->
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title"> 기본정보 </h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col1634">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 비밀번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <m-trans-key-input
                      v-if="isMobile && isMtrans"
                      ref="password"
                      class="input-long"
                      :class="password_error ? 'error' : ''"
                      v-model="password"
                      type="numberMax4"
                      dialog="Y"
                      start="0" 
                      end="-1"
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt"         
                    />
                    <mo-text-field 
                      v-else
                      class="input-long" 
                      v-model="password" 
                      :class="password_error ? 'error' : ''"
                      ref="password" 
                      type="password"
                      maxlength="4"   
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 비밀번호확인 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <m-trans-key-input
                      v-if="isMobile && isMtrans"
                      ref="password2"
                      class="input-long"
                      :class="password2_error ? 'error' : ''"
                      v-model="password2"
                      type="numberMax4"
                      dialog="Y"
                      start="0" 
                      end="-1"
                      :isClear="lv_isClear2"
                      @masked-txt="fn_SetMaskedTxt2"         
                    />
                    <mo-text-field 
                      v-else
                      class="input-long" 
                      v-model="password2" 
                      :class="password2_error ? 'error' : ''"
                      type="password" 
                      ref="password2"
                      maxlength="4" 
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 재투자여부 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-radio v-model="reinv_yn" @input="fn_reinvCheck" :disabled="reinv_yn_disabled"> 예 </mo-radio>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 세제한도조회 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field  
                    numeric
                      class="w116 aR" 
                      v-model="join_limit_amt" 
                      :disabled="join_limit_amt_disabled" 
                      :class="join_limit_amt_error ? 'error' : ''"
                      mask="number" 
                      ref="joinLimitAmt"
                    />
                    <mo-button 
                      class="btn-input" 
                      :disabled="Btn_life_search" 
                      @click="fn_Btn_life_search_onclick"
                      > 한도조회 </mo-button>
                    <mo-text-field mask="number" class="input-long aR" v-model="ME_LIMIT" readonly />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 저축종류 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-radio v-model="save_tp" @input="fn_save_tp" :disabled="save_tp_disabled"> 자유적립식 </mo-radio>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 권유자 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      class="w116" 
                      v-model="inv_empno" 
                      ref="invEmpno" 
                      :class="inv_empno_error ? 'error' : ''" 
                      readonly 
                    />
                    <mo-button :disabled="Btn_emp_search" @click="fn_Btn_emp_seler_search_onclick(0)"> 사원찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="inv_empnm" readonly />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 저축기간 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                    class="aR w80" 
                    type="number" 
                    v-model.lazy="save_term"
                    :class="save_term_error ? 'error' : ''" 
                    ref="saveTerm"
                    @blur="fn_ed_save_term()" 
                    />
                    <span> 월 </span>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 판매직원 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w116" v-model="inv_seler" ref="invSeler" readonly />
                    <mo-button @click="fn_Btn_emp_seler_search_onclick(1)" ref="btnSelerSearch"> 사원찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="inv_selernm" readonly />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 연금지급개시일 </span> <!-- class명 "emphasis" 추가 2023-04-07 jy -->
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker 
                      class="input-long" 
                      v-model="pension_paym_start_dt" 
                      :class="pension_paym_start_dt_error ? 'error' : ''"
                      :bottom="false" 
                      :disabled="cal01"
                      
                      />
                      <!-- ref="pensionPaymStartDt" -->
                      <span class="wsN">{{lv_paym_start_dt}}</span>
                  </div>
                </td>
                <th>
                  <span> 상담텔러 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w116" v-model="inv_counsel" ref="invCounsel" readonly/>
                    <mo-button class="btn-input" :disabled="Btn_counsel_search" @click="fn_Btn_counsel_search_onclick"> 사원찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="inv_counselnm" readonly />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 연금지급기간 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w80 aR" v-model="pension_paym_term" readonly /> <!-- class명 "aR" 추가 2023-04-07 jy -->
                    <span> 월 </span>
                  </div>
                </td>
                <th>
                  <span> 지역단FP </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w116" v-model="inv_pm" ref="invPm" readonly />
                    <mo-button :disabled="Btn_pm_search" @click="fn_Btn_pm_search_onclick"> FP찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="inv_pmnm" readonly />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 연금지급주기 </span> <!-- class명 "emphasis" 추가 2023-04-07 jy -->
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown 
                    :items="lv_commCode.code350" 
                    v-model="pension_paym_cycle"
                    :class="pension_paym_cycle_error ? 'error' : ''"
                    ref="pensionPaymCycle"
                    placeholder="선택하세요" />
                  </div>
                  
                </td>
                <th>
                  <span> FP센터 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w116" v-model="inv_fp" readonly />
                    <mo-button class="btn-input" :disabled="Btn_fp_search" @click="fn_Btn_fp_search_onclick('FP')"> FP찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="inv_fpnm" readonly />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 최초가입일 </span>
                </th>
                <td>
                 <div class="wrap-input row">
                    <mo-date-picker 
                      class="input-long" 
                      v-model="oring_open_dt" 
                      :class="oring_open_dt_error ? 'error' : ''" 
                      :bottom="false" 
                      :disabled="cal02"
                      ref="oringOpenDt"
                    />
                  </div>
                </td>
                <th>
                  <span> FO센터 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w116" v-model="inv_fo" readonly />
                    <mo-button class="btn-input" :disabled="Btn_fo_search" @click="fn_Btn_fp_search_onclick('FO')"> FO찾기 </mo-button>
                    <mo-text-field class="input-long" v-model="inv_fonm" readonly />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 연금지급계좌 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="Rad_pension_btrans_yn" @input="fn_pension_btrans_yn_onitemchanged"  v-model="pension_btrans_yn" class="row"/>
                    <mo-dropdown 
                    class="w260" 
                    :items="ds_1011_seq_no" 
                    v-model="pension_btrans_bank_seq" 
                    placeholder="선택하세요" 
                    :disabled="pension_btrans_yn == 'N'"
                    
                    /> <!-- class명 변경 "w260" / :disabled 추가 2023-04-07 jy -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="wsN"> 매월자동이체신청여부 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row">
                    <mo-radio-wrapper :items="Rad_btrans_gb" v-model="btrans_gb"/>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row justify-between">
          <h2 class="table-title"> 펀드가입 </h2>
          <mo-button @click="fn_btn_dataCreate00_onclick"> 추가 </mo-button>
        </div>
        <div class="wrap-table h-scroll">
          <table class="table row-type">
            <!-- <colgroup>
              <col width="50px">
              <col width="190px">
              <col width="*">
              <col width="*">
              <col width="*">
              <col width="150px">
            </colgroup> -->
            <thead>
              <tr>
                <th class="w50"> 삭제 </th>
                <th class="w190"> 펀드코드 </th>
                <th> 펀드명 </th>
                <th> 펀드적합여부 </th>
                <th> 권유자 </th>
                <th class="w150"> 가입금액 </th>
              </tr>
            </thead>
            <tbody> <!-- v-for 로 교체 2023-04-28 jy -->
              <tr v-for="(row, i) in ds_fund_list" :key="i">
                <td>
                  <mo-button class="btn-pop-minus" @click="fn_fund_list_delete_oncellclick(i)"> </mo-button>
                </td>
                <td class="row justify-around"> 
                  <p class="w100"> {{row.fund_cd}} </p> 
                  <mo-button @click="fn_fund_list_fndCd_oncellclick(i)" >펀드코드</mo-button> 
                </td>
                <td class="aL"> {{row.fund_nm}} </td>
                <td> {{row.fund_prop_grade_nm}} </td>
                <td> {{row.inv_empnm}} </td>
                <td> 
                  <mo-decimal-field 
                    numeric
                    type="number" 
                    class="w130" 
                    v-model="row.buy_req_amt" 
                    @input="fn_sum_buy_req_amt" 
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5"> <strong>합계</strong> </td>
                <td class="pr-5 aR"> <strong>{{$bizUtil.numberWithCommas(buy_req_amt_sum)}}</strong> </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <div class="left">
            <mo-button point size="large" v-if="lv_isProcess"  @click="fn_GoNextPage('MSPFS304M')" :disabled="lv_btn_next"> 다음 </mo-button>
          </div>
          <mo-button primary size="large"  :disabled="btn_confirm" @click="fn_confirm">확인</mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->

    <!-- popup start -->
    <msp-fs-301p
      ref="popup301"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack301"
      :popupObj="pPopup301Obj"
    ></msp-fs-301p>
    <msp-fs-303p
      ref="popup303"
      @fs-popup-callback="fn_Popup_CallBack303"
      :popupObj="pPopup303Obj"
    ></msp-fs-303p>
    <msp-fs-305p
      ref="popup305"
      @fs-popup-callback="fn_Popup_CallBack305"
      :popupObj="pPopup305Obj"
    ></msp-fs-305p>
    <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack308"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>
    <msp-fs-309p
      ref="popup309"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack309"
      :popupObj="pPopup309Obj"
    ></msp-fs-309p>
    <msp-fs-310p
      ref="popup310"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack310"
      :popupObj="pPopup310Obj"
    ></msp-fs-310p>
    <msp-fs-312p
      ref="popup312"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack312"
      :popupObj="pPopup312Obj"
    ></msp-fs-312p>
    <msp-fs-313p
      ref="popup313"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack313"
      :popupObj="pPopup313Obj"
    ></msp-fs-313p>
    <msp-fs-315p
      ref="popup315"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack315"
      :popupObj="pPopup315Obj"
    ></msp-fs-315p>
    <msp-fs-321p
      ref="popup321"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack321"
      :popupObj="pPopup321Obj"
    ></msp-fs-321p>
    <msp-fs-322p
      ref="popup322"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack322"
      :popupObj="pPopup322Obj"
    ></msp-fs-322p>
    <msp-fs-323p
      ref="popup323"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack323"
      :popupObj="pPopup323Obj"
    ></msp-fs-323p>
    <msp-fs-316p
      ref="popup316"
      @fs-popup-callback="fn_SearchValid({key: 'v'}, 0)"
      :pCust316Obj="pCust316Obj"
    ></msp-fs-316p>
    
    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>
    
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)
  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
  import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통) 
  import FSBizUtil from '~/src/ui/fs/comm/FSBizUtil'
  import FSAmlUtil from '~/src/ui/fs/comm/FSAmlUtil'
  import FSFormUtil from '~/src/ui/fs/comm/FSFormUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSFatcaUtil from '~/src/ui/fs/comm/FSFatcaUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
  import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'

  import MSPFS301P from '~/src/ui/fs/MSPFS301P'  // AML실제고유자여부확인
  import MSPFS303P from '~/src/ui/fs/MSPFS303P'  // FATCA개인 팝업
  import MSPFS305P from '~/src/ui/fs/MSPFS305P'  // 고위험승인요청 팝업
  import MSPFS308P from '~/src/ui/fs/MSPFS308P'  // 고객정보 팝업
  import MSPFS309P from '~/src/ui/fs/MSPFS309P'  // 세제상품전금융기관등록조회 팝업
  import MSPFS310P from '~/src/ui/fs/MSPFS310P'  // 펀드정보 팝업
  import MSPFS312P from '~/src/ui/fs/MSPFS312P'  // 상담텔러 팝업 
  import MSPFS313P from '~/src/ui/fs/MSPFS313P'  // 업무대리인 조회 팝업
  import MSPFS315P from '~/src/ui/fs/MSPFS315P'  // 자금세탁(AML) 고객확인 재이행 팝업
  import MSPFS321P from '~/src/ui/fs/MSPFS321P'  // 판매직원,권유자 팝업 
  import MSPFS322P from '~/src/ui/fs/MSPFS322P'  // 지역단FP 팝업 
  import MSPFS323P from '~/src/ui/fs/MSPFS323P'  // FP센터, FO센터 팝업
  import MSPFS316P from '~/src/ui/fs/MSPFS316P'  // 조력자조회 팝업 
  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS303M",
    screenId: "MSPFS303M",
    components: {
      'fs-header': FSHeader,
      'fs-alert-popup': FSAlertPopup,
      'fs-cust-info': FSCustInfo,
      'msp-fs-301p': MSPFS301P, //고객정보 
      'msp-fs-303p': MSPFS303P,
      'msp-fs-305p': MSPFS305P,
      'msp-fs-308p': MSPFS308P,  
      'msp-fs-309p': MSPFS309P,
      'msp-fs-310p': MSPFS310P, //펀드정보
      'msp-fs-312p': MSPFS312P, //상담텔러
      'msp-fs-313p': MSPFS313P,
      'msp-fs-315p': MSPFS315P,
      'msp-fs-321p': MSPFS321P, //판매직원,권유자 
      'msp-fs-322p': MSPFS322P, //지역단FP
      'msp-fs-323p': MSPFS323P, //FP센터, FO센터
      'msp-fs-316p': MSPFS316P
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_SetCommCode()
    },
    mounted() {
      //초기화먼저
      this.fn_init()

      this.$bizUtil.insSrnLog(this.$options.screenId)
      // 메인화면에서 다이렉트로 진입한 경우 STEP 숨김
      if( !FSCommUtil.gfn_isNull(this.$route.params.isStep) ) {
        if( !this.$route.params.isStep ) {
            this.pHeaderObj.step = ''
        }
      }

      if( !FSCommUtil.gfn_isNull(this.$route.params.ed_group_no) || !FSCommUtil.gfn_isNull(this.$route.params.ed_cust_no) ) {
        setTimeout(() => {
          // console.log('이전 step에서 받아온 고객 번호 ', this.$route.params);
          this.lv_ed_cust_no.value = FSCommUtil.gfn_isNull(this.$route.params.ed_cust_no) ? this.$route.params.ed_group_no.substr(0, 7) : this.$route.params.ed_cust_no
          this.coprtPesonAsgnPopChkYn  = this.$route.params.ed_insert
          // 고객번호 매핑 후 조회
          this.fn_SearchValid({key: 'v'}, 0)

        }, 100)
      }

      
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        pHeaderObj: {     
          title: '연금계좌신규개설'
          ,step: 7
        },
        lv_isProcess: this.getStore('fsStore').getters.getState.isProcess,
        // 공통 객체
        lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,

        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
        lv_isClear: false,            // 보안키패드 초기화
        lv_isClear2: false,            // 보안키패드 초기화

        lv_commCode : {}, 
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
        pAlertPopupObj : {},

        lv_app : {},

        // 팝업
        pPopup301Obj: {}, // AML
        pPopup303Obj: {}, // fatca
        pPopup305Obj: {},
        pPopup308Obj: {}, // 고객정보
        pPopup309Obj: {}, // 펀드정보
        pPopup310Obj: {}, // 펀드정보
        pPopup312Obj: {}, // 상담텔러
        pPopup313Obj: {},
        pPopup315Obj: {},
        pPopup321Obj: {}, // 판매직원,권유자
        pPopup322Obj: {}, // 지역단FP
        pPopup323Obj: {}, // FP센터,FO센터
        pCustInfoObj: {}, //고객정보
        pCust316Obj: {},  // 조력자조회 

        lv_ed_cust_no:{}, //고객번호
        lv_ed_cust_nm:{}, //고객이름

        cust_no     : '', // 고객번호 
        cust_nm     : '', // 고객명 
        cust_grade  : '', // 투자성향 value 
        proc_gb     : '11', // 신규구분 value 
        agnt_chk_yn : {
          value : '',
          error : false
        }, // 대리인 여부 value 
        std_dt      : '0000.00.00', // 동의기간 시작 날짜
        end_dt      : '0000.00.00', // 동의기간 끝 날짜 

        cust_grade_disabled     : true, // 투자성향 disabled 
        join_limit_amt_disabled : true, //
        reinv_yn_disabled : true, //재투자여부 disabled 
        save_tp_disabled : true, //저축종류 disabled 
        cal01 : false, //연금지급개시일 
        cal02 : true, //최초가입일 disabled 

        cust_no_error : false, // 고객번호 error class

        password           : '', // 비밀번호 
        password2          : '', // 비밀번호확인
        reinv_yn              : 'Y', // 재투자 여부 
        join_limit_amt        : '', // 세제한도조회
        ME_LIMIT              : '', //세제한도조회 
        save_tp               : '14', // 저축종류
        inv_empno             : '', // 권유자사번
        inv_empnm             : '', // 권유자명
        save_term             : '', // 저축기간 
        inv_seler             : '', // 판매직원사번
        inv_selernm           : '', // 판매직원명
        pension_paym_start_dt : '', // 연금지급개시일 
        lv_paym_start_dt      : '', // 연금지급개시일 계싼 
        inv_counsel           : '', // 상담텔러사번
        inv_counselnm         : '', // 상담텔러명
        pension_paym_term     : '120', // 연금지급기간
        inv_pm                : '', // 지역단FP 사번
        inv_pmnm              : '', // 지역단FP명
        pension_paym_cycle    : '10', // 연금지급주기 
        inv_fp                : '', // FP센터 
        inv_fpnm              : '', //FP센터명 
        oring_open_dt         : '', // 최초가입일
        inv_fo                : '', // FO사번
        inv_fonm              : '', // FO명

        pension_btrans_yn       : 'N', //연금지급계좌 선택값 
        pension_btrans_bank_seq : '', // 연금지급계좌
        btrans_gb               : 'C', // 매월 자동이체 신청여부
        
        buy_req_amt_sum         : '', // 가입금액 총 

        ds_fund_list       : [], // 펀드가입리스트 ds
        ds_1011_seq_no     : [], // 연금지급계좌 ds 
        ds_1011_cust_grade : [], //
        ds_base_info       : [], //통합계좌여부와 매입가능금액조회 결과 ds
        
        ds_tax_prime       : [], 
        ds_invest_servey_info_slts : [], //신탁수탁 (투자성향설문데이터조회 result)
        ds_pension_paym_start_dt : [], //
        ds_invemp_detail : [], 
        ds_cu1011 : [], 
        ds_report : [], // 전자서식
        ds_result : [], // 등록 후 결과 값 저장할 데이터셋

        // disabled btn 
        Btn_cust_search    : false, // 고객정보
        Btn_fo_search      : false, // fo찾기
        Btn_fp_search      : false, // fp찾기
        Btn_pm_search      : false, // 지역단fp찾기
        Btn_counsel_search : false, // 상담텔러찾기 
        Btn_seler_search   : false, // 판매직원찾기
        Btn_emp_search     : false, // 권유자찾기
        Btn_life_search    : false, // 세제한도조회 

        lv_btn_next      : true,
        btn_confirm      : false,
        btn_init         : false,
        btn_autoComplete : false,
        //-----------asis전역변수 start--------------------------------------------------------
        gDept_cd         : this.getStore('fsStore').getters.getBasInfo.data.brcd,  //부서코드

        order_type       : 0,     //latetrading 구분값1:당일매입 ,1> 익일매입
        TAX_GB           : "11",  //개인을 기본값으로 지정합니다.
        CORP_GB          : "N",  //개인을 기본값으로 지정합니다.
        inter_useyn      : "Y",   //연합회 사용여부
        vCust_grade      : "",	  //펀드적합성 처리
        vTax_tp_gb       : "13",  //연금계좌
        vPro_investor_yn : "",    //전문투자자여부 추가
        vOld_yn          : "",    //고령자 체크 후 고령자 여부 Y/N 값 받아오는 변수
        vLn_yn           : "",    //대출유무체크 결과  ASR210300475_불공정영업행위 금지 로직 반영
        paym_start_dt    : '', // 연금지급개시일 계싼 
        vInv_empno       : "",
        vInv_empnm       : "",
        vUser_Grpcd      : "",
        cu1011_userGrpCd : "", // 임시 저장
        gDate            : '',//this.gfn_getBusyDate(),

        nRow             : 0,

        rname_no_chk     : "",  //Gds_Temp_Cust 에서 받아올 값 저장 _실명번호
        rname_no_gb_chk  : "",  //Gds_Temp_Cust 에서 받아올 값 저장 _실명번호구분
        cust_nm_chk      : "",  //Gds_Temp_Cust 에서 받아올 값 저장 _고객명

        ds_pbpr_detail : [], //passbook ds 

        /* ASR201100735_그룹신규(#1010) 브릿지계좌신규 등 업무보완 (2020.12.22 이승하)
          ㄴ고령자 나이 기준 70->65 */
        stdOldAge : 65,
        //-----------asis전역변수 end--------------------------------------------------------

        lv_reObjRtn   : {},              // AML실제 소유주여부 팝업 결과
        lv_reCall_yn  : '',             // 자금세탁(AML) 고객확인 재이행 여부

        lv_reportData : {}, // 전자서식 발행 시 필요한 데이터
        lv_serverType : process.env.NODE_ENV, 

        password_error : false,
        password2_error  : false,
        inv_empno_error : false,
        save_term_error : false,
        pension_paym_start_dt_error : false,
        pension_paym_cycle_error : false,
        join_limit_amt_error : false,
        oring_open_dt_error : false,

        lv_bfcer_acct_no : '' , // 개설된 계좌번호

        lv_rlnm_cnf_no : '', // 고객 주민등록번호 (309P param)

        lv_masked_val: '',    // 마스크 변수 체크
        lv_masked_val2: '',    // 마스크 변수 체크

        lv_password_check: false,      // 비밀번호 확인

        // 고령자 조력자 팝업 확인 클릭 여부
        coprtPesonAsgnPopChkYn: '',
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      //연금지급계좌 
      Rad_pension_btrans_yn() {
        let rtn = [];
        rtn.push({value: 'N', text: '브릿지(예수금)'});
        rtn.push({value: 'Y', text: '타은행'});
        return rtn;
      },
      //신규구분 
      Rad_proc_gb() {
        let rtn = [];
        rtn.push({value: '11', text: '일반신규'});
        rtn.push({value: '21', text: '연금이체신규'});
        return rtn;
      },
      //대리인
      rdo_agnt_chk_yn() {
        let rtn = [];
        rtn.push({value: 'Y', text: '예'});
        rtn.push({value: 'N', text: '아니오'});
        return rtn;
      },
      //매월 자동이체 신청여부 
      Rad_btrans_gb() {
        let rtn= [];
        rtn.push({value: 'C', text: 'CMS이체'});
        rtn.push({value: 'S', text: '급여이체'});
        rtn.push({value: 'N', text: '아니오(미등록)'});
      return rtn;
      },
      

      modal1() {return this.$refs.modal1},
      modal2() {return this.$refs.modal2},
      modal3() {return this.$refs.modal3},
      modal4() {return this.$refs.modal4},
      modal5() {return this.$refs.modal5},
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
     
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      
      /******************************************************************************
      * Function명 : fn_SetMaskedTxt
      * 설명       : 보안키패드 체크
      ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_val = val
      },

      async fn_SetMaskedTxt2(val) {
        this.lv_masked_val2 = val
        this.lv_password_check = false
        if(this.lv_masked_val.length === 4 && this.lv_masked_val2.length === 4) {
           this.password2_error = false
           this.lv_password_check = await this.fn_pass_check()
           if(!this.lv_password_check) {
              this.lv_isClear2 = !this.lv_isClear2 ? true : false
              this.password2 = ''
              this.password2_error = true              
           }            
        }
      },

      /***************************************************************************************
       * Function명 : fn_pass_check
       * 설명       : 비밀번호값 비밀번호확인값 체크
      *****************************************************************************************/
      async fn_pass_check() {
        let rtn = await this.$commonUtil.comparePassword(this, this.password, this.password2)
        if(rtn !== 'Y') {
          return false
        }

        return true
      },

      
      /**********************************************************************************
       * Function명 : fn_enableComponent
       * 설명       :  WFtitleCRUDbtn Div 의 버튼 활성,비활성 처리
       **********************************************************************************/
      fn_enableComponent(){
        this.btn_confirm = true 
        this.btn_init = false
      },

      /***************************************************************************************
       * Function명 : fn_init
       * 설명       :  초기화
      *****************************************************************************************/
      fn_init(){
        this.fn_enableComponent()//버튼 제어
        this.rname_no_chk     = this.$route.params.ed_rname_no  // 실명번호 
        this.proc_gb            = '11' //신규구분 
        this.pension_paym_cycle = '10' // 연금지금주기

        this.reinv_yn_disabled = true //재투자여부 disabled 
        this.save_tp_disabled  = true //저축종류 disabled 

        this.ds_1011_cust_grade         = []
        this.ds_1011_cust               = []
        this.ds_invest_servey_info_slts = []
        this.ds_tax_prime               = []
        this.ds_pension_paym_start_dt   = []
        this.ds_1011_seq_no             = []
        this.ds_invemp_detail           = []
        this.ds_cu1011                  = []
        this.ds_fund_list               = []
              
        this.CORP_GB    = 'N'       //개인을 기본값으로 지정합니다.
        this.order_type = 'buyorder'//매입신청 디폴트
              
        this.cust_no = ''
        this.cust_nm = ''
        // 검색조건
        this.lv_ed_cust_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_cust_no')      // 고객번호
        this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1)

        this.cust_grade = ''   //고객투자성향
        this.cust_grade_disabled = true
        this.std_dt = '0000.00.00'   //투자적합성 유효기간
        this.end_dt = '0000.00.00'
        // this.order_type = order_type;

        this.password = ''
        this.password2 = ''
        
        this.pension_paym_start_dt = '' //연금지급개시일
        this.pension_btrans_bank_seq = '' //연금지급계좌

        this.btrans_gb = 'C' //자동이체신청여부 'CMS이체'로 세팅
        // ob 없음 
        // this.Div01.div_detail.Rad_ob_yn.set_enable(true);               //OB구분
        // this.Div01.div_detail.Rad_ob_yn.set_value("N"); 

        this.pension_btrans_yn = 'N'		//연금지급계좌 '예수금'으로 세팅
        this.reinv_yn = 'Y'		        //재투자로 고정
        this.save_tp = '14'		        //자유적립식으로 고정

        //세제한도,사원찾기 버튼
        this.Btn_life_search = false
        this.Btn_pm_search = false
        this.Btn_seler_search = false
        this.Btn_counsel_search = false
        this.Btn_emp_search = false
        this.Btn_fp_search = false
        this.Btn_fo_search = false

        //세제한도금액
        this.join_limit_amt = ''
        this.join_limit_amt_disabled = true

        this.ME_LIMIT          = ''
        this.inv_empnm         = '' //권유자
        this.inv_empno         = ''
        this.inv_seler         = '' //판매자
        this.inv_selernm       = ''
        this.inv_counsel       = '' //삼당텔러
        this.inv_counselnm     = ''
        this.inv_fo            = ''
        this.inv_fonm          = ''
        this.inv_fp            = ''
        this.inv_fpnm          = ''
        this.inv_pm            = ''
        this.inv_pmnm          = ''
        this.pension_paym_term = '120' //연급지급기간 10년(120월)으로 고정
        this.save_term         = ''	//저축기간 5년(60월) Default
        this.lv_paym_start_dt  = ''	//연금지급개시일_계산
        this.proc_gb = '11' // 신규구분 초기화 = 일반신규 
        this.oring_open_dt = ''  //최초가입일
        this.cal02 = true

        //보안키패드 관련
        this.lv_isClear = !this.lv_isClear ? true : false
        this.lv_isClear2 = !this.lv_isClear2 ? true : false
        this.lv_masked_val = ''
        this.lv_masked_val2 = ''


		    //프로세스에서 받아온 파라미터에 주민등록 번호가 있을 경우 
        if( !FSCommUtil.gfn_isNull(this.$route.params.ed_rname_no)){
          this.pPopup309Obj = {
            rlnm_cnf_no : this.$route.params.ed_rname_no
          }
        }
        // 초기화 버튼 클릭시 
        this.password_error = false
        this.password2_error  = false
        this.inv_empno_error = false
        this.save_term_error = false
        this.pension_paym_start_dt_error = false
        this.pension_paym_cycle_error = false
        this.join_limit_amt_error = false
        this.oring_open_dt_error = false
      },
      
      /***************************************************************************************
       * Function명 : fn_popup308
       * 설명       : 308 - 고객검색 팝업 
      *****************************************************************************************/
      fn_popup308(){
        this.pPopup308Obj = {
          bfcer_cust_no : this.lv_ed_cust_no.value, 
          fnd_cust_nm : this.lv_ed_cust_nm.value
        }
        this.$refs.popup308.fn_Open()
      },

      async fn_Popup_CallBack308(popupId, objRtn){
        if (FSCommUtil.gfn_isNull(objRtn)) {
          this.lv_ed_cust_no.value = ''
          this.lv_ed_cust_nm.value = ''
        } else {	
          this.lv_ed_cust_no.value = objRtn // 수익증권고객번호
          this.fn_SearchValid({key: 'v'}, 0)
        }
      },
      /***************************************************************************************
        신탁수탁 시스템 투자성향 설문 데이터 조회 callback
      *****************************************************************************************/
      async fn_SY1005_call_back(response){
        this.ds_1011_cust_grade = response.data.sy000700_O_00VO
        console.log('this.ds_1011_cust_grade', this.ds_1011_cust_grade)
        //투자성향정보 확인 
        //1) 투자성향정보  없는경우 7005 화면이동 
        //2) 투자성향정보  존재 하지만 당일정보가 아닌경우 7005 화면이동 
        if(this.ds_1011_cust_grade.length > 0){
          var cust_grade      = this.ds_1011_cust_grade[0].ivt_prpns_anly_grd_cd //투자성향
          var avail_std_dt    = this.ds_1011_cust_grade[0].avail_std_dt          //설문등록일(유효기간시작일)
          var invest_inv_yn   = this.ds_1011_cust_grade[0].ivt_cnvs_hop_yn       //정보미제공/투자권유불원
          var pro_investor_yn = this.ds_1011_cust_grade[0].tlgm_ivt_peson_yn     //전문투자자여부
          if( cust_grade   == null )  cust_grade    = ''
          if( avail_std_dt == null )  avail_std_dt  = ''
          if( invest_inv_yn == null ) invest_inv_yn = ''

          if (await FSFormUtil.gfn_chkInvest_servey(this, cust_grade, avail_std_dt, '', '', '', this.lv_ed_cust_no.value, this.fn_AlertPopup) === true ) {
            this.cust_grade = cust_grade //투자성향

            // 정보미제공/투자권유불원의 경우 확인서 징구 확인
            if (invest_inv_yn === 'N' && pro_investor_yn !== 'Y') {
              let t_popupObj = {content: '부적합투자자는 금융취약자로 분류되어 투자권유가 불가합니다.'}
              this.fn_AlertPopup(0, t_popupObj)
              this.btn_confirm = true
              return 
            }
            // 유효기간 표시
            if (FSCommUtil.gfn_length(this.ds_1011_cust_grade[0].avail_std_dt) !== 0) {
              this.std_dt = FSCommUtil.gfn_dateReplace(2, this.ds_1011_cust_grade[0].avail_std_dt)
              this.end_dt = FSCommUtil.gfn_dateReplace(2, this.ds_1011_cust_grade[0].avail_end_dt)
            }
            // 고객정보조회
            this.fn_CustInfo()
          }
        } else {
          FSFormUtil.gfn_chkInvest_servey(this, '', '', '', '', '', this.lv_ed_cust_no.value, this.fn_AlertPopup)  
          return
        }
      },

      fn_selectCustInfoList() {
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570167_S'
        this.eaiCommObj.params = {
          bfcer_cust_no : this.lv_ed_cust_no.value, // 고객명, Length : 150
          rlnm_cnf_no_encr : this.rname_no_chk 
        }
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SY1005_call_back2)
      },
      /***************************************************************************************
        고객조회 callback
      *****************************************************************************************/
      fn_SY1005_call_back2(response) {
        this.ds_1011_cust = response.data.sy100500_O_00VO
        // 고객 검색후 자료가 없는 경우 Clear Set
        if (FSCommUtil.gfn_length(this.ds_1011_cust) === 0) {
          this.lv_ed_cust_no.value = ''
          this.lv_ed_cust_nm.value = ''
          return
        } else {
          this.lv_ed_cust_no.value = this.ds_1011_cust[0].bfcer_cust_no
          this.lv_ed_cust_nm.value = this.ds_1011_cust[0].fnd_cust_nm
          this.CORP_GB = this.ds_1011_cust[0].corp_yn

          if (this.lv_ed_cust_nm.value === '') {
            let t_popupObj = {content: '해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다.'}
            this.fn_AlertPopup(0, t_popupObj)
            return
          }
        } 
        this.fn_baseInfo()
      },

      fn_baseInfo(){
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570250_S'
        this.eaiCommObj.params = {
          cust_no : this.lv_ed_cust_no.value // 고객명, Length : 150
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_baseInfo_call_back)
      },
      /***************************************************************************************
        통합계좌여부와 매입가능금액 조회 callback
      *****************************************************************************************/
      fn_baseInfo_call_back(response){
        this.ds_base_info = response.data
        if (this.ds_base_info !== undefined && this.ds_base_info !== null) {
          if(this.ds_base_info.unty_apamt_accn_yn === 'O') {
            var paramObj = {}
            paramObj.rmvl_srn_id   = 'CU101100'
            paramObj.bfcer_cust_no = this.lv_ed_cust_no.value
            paramObj.Fnd_cust_nm   = this.lv_ed_cust_nm.value
            //최초고객
            let t_popupObj = {
              cancel: false,
              confirm: true,
              confirmFunc: '',
              content: '최초로 펀드계좌를 개설하려는 고객입니다.\n\n브릿지(예수금)계좌를 개설하셔야 합니다.'
            }
            this.fn_AlertPopup(0, t_popupObj)
            this.btn_confirm = true
          } else {
            this.btn_confirm = false
          }
        }
      },    

      /******************************************************************************
       * Function명 : fn_movePage
       * 설명       : 페이지 이동 
       * MSPFS300M  : 그룹신규 페이지 
       ******************************************************************************/
      fn_movePage(param) {
        this.$router.push({
          name: 'MSPFS300M', 
          params: param
        })
      },

      /***************************************************************************************
       * Function명 : fn_SearchValid(div_search_Me_cust_no_onchar)
       * 설명       : 고객번호 search
      *****************************************************************************************/
      async fn_SearchValid(event, type, asgnPopChkYn = false){

        if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) !== 7 || !FSCommUtil.gfn_isNum(this.lv_ed_cust_no.value)) {
          FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
          this.lv_ed_cust_no.error = true
          this.$refs['custNo'].focus()
          return 
        } else {
          this.lv_ed_cust_no.error = false
          if (asgnPopChkYn) {
            this.coprtPesonAsgnPopChkYn = false
          }
          // 23.12.18 조력자 조회 팝업
          if(FSCommUtil.gfn_isNull(this.coprtPesonAsgnPopChkYn) || !this.coprtPesonAsgnPopChkYn ){
            if( await this.fn_after_check(1) == false ) {
              return
            }
          }
          // 불공정영업행위 금지
          if( await this.fn_after_check(0) == false ) {
            // 조회/확인 버튼 비활성화
            return
          }
          // 자금세탁방지 KYC RULE Chec
          if( await this.fn_after_check(2) == false ) {
            return
          }

          // FATCA(해외금융계좌신고제도) Check
          if( await this.fn_after_check(3) == false ) {
            return
          }
          // 신탁수탁 시스템 투자성향 설문 데이터 조회
          this.fn_get_invest_uservey_info_slts(this.lv_ed_cust_no.value)
        }
      },
      /******************************************************************************
       * Function명 : fn_after_check
       * type
          0: 불공정영업행위 금지 
          1: 초고령자 체크 (만 80세 이상 초고령자 가입 불가)
          2: 자금세탁방지 (AML) KYC RULE Check
          3: FATCA(해외금융계좌신고제도) Check
       ******************************************************************************/
      async fn_after_check(type) {
        console.log('fn_after_check....')

        let t_popupObj = {}


        // 불공정영업행위 금지
        if( type == 0 ) {

          // 대출유무체크
          let lv_vLn_yn = await FSBizUtil.gfn_chkLnCont_Yn(this, this.lv_ed_cust_no.value, this.fn_AlertPopup)
          if( lv_vLn_yn != 'N' ) {
            return false
          }
        }


        // 초고령자 체크 (만 80세 이상 초고령자 가입 불가)
        if( type == 1 ) {

          let t_sy000500_data = await FSBizUtil.gfn_cust_no_sy000500(this, {bfcer_cust_no: this.lv_ed_cust_no.value}, this.fn_AlertPopup)

          // 고령자정보조회
          if( t_sy000500_data.isFlag == true ) {
            console.log(t_sy000500_data)            

            let t_rlnm_cnf_no_encr    = FSCommUtil.gfn_trim(t_sy000500_data.rlnm_cnf_no_encr)    // 실명번호
            let t_rlnm_no_knd_cd      = FSCommUtil.gfn_trim(t_sy000500_data.rlnm_no_knd_cd)      // 실명번호구분
            let t_fnd_cust_nm         = FSCommUtil.gfn_trim(t_sy000500_data.fnd_cust_nm)         // 고객명
            let t_coprt_peson_asgn_yn = FSCommUtil.gfn_trim(t_sy000500_data.coprt_peson_asgn_yn) // 조력자등록여부

            /* ASR210200794_투자상품 판매 프로세스 강화 개발 요청(1) (2021.03.24 이승하)
              →만 80세 이상 초고령자 펀드 가입 불가 */
            this.vOld_yn = await FSBizUtil.gfn_chkOldAge_Yn(this, t_rlnm_cnf_no_encr, t_rlnm_no_knd_cd, this.fn_AlertPopup, 'N')
            console.log('vOld_yn :: ' + this.vOld_yn)

            if( FSCommUtil.gfn_isNull( FSCommUtil.gfn_trim(t_fnd_cust_nm) ) ){
              t_popupObj = {
                content: '해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다.',
              }
              this.fn_AlertPopup(0, t_popupObj)
              return false
            }

            // 고령자 조력자 체크
            let coprtPesonAsgnData = {
              cust_no: this.lv_ed_cust_no.value,
              cust_nm: t_fnd_cust_nm,
              old_yn: this.vOld_yn,
              coprt_peson_asgn_yn: t_coprt_peson_asgn_yn,
              menu_id: 'MSPFS303M',
              coprt_peson_asgn_pop_chk_yn: this.coprtPesonAsgnPopChkYn,
            }
            if( FSBizUtil.gfn_chkCoprtPesonAsgn_Yn(this, coprtPesonAsgnData, this.fn_AlertPopup) != true ) {
              return false
            }

          } else {
            this.lv_ed_cust_no.value = ''
            return false
          }
        }


        // 자금세탁방지 (AML) KYC RULE Check 
        if( type == 2 ) {
          console.log('자금세탁방지11');

          // 실제소유자여부 당일진행여부 확인
          // vue 에서 팝업 호출 문제로 AML팝업 확인시 reObjRtn 값에 따라 처리되도록 추가
          if( FSCommUtil.gfn_isNull(this.lv_reObjRtn.cust_no) || this.lv_ed_cust_no.value != this.lv_reObjRtn.cust_no ) {
            let t_real_owner_check = await FSAmlUtil.gfn_aml_real_owner_check(this, this.lv_ed_cust_no.value, this.fn_AlertPopup, this.fn_OpenPopup)
            if( t_real_owner_check != true ) {
              this.btn_confirm = true   // disabled
              return false
            }
          }

          // 자금세탁방지 및 테러 자금방저정책 ( AML ) 체크
          let aml_check_data = await FSAmlUtil.gfn_aml_check(this, this.lv_ed_cust_no.value, 'CU1010', '', this.lv_reCall_yn, this.lv_reObjRtn, this.fn_AlertPopup, this.fn_OpenPopup)
          if( aml_check_data != true ) {
            this.btn_confirm = true   // disabled
            return false
          }          
        }

        // FATCA(해외금융계좌신고제도) Check
        if( type == 3 ) {

          let t_fatca_check_data = await FSFatcaUtil.fatca_check(this, this.lv_ed_cust_no.value, 'CU1011', this.fn_OpenPopup, this.fn_AlertPopup)
          if( t_fatca_check_data != true ) {
            // if( FSCommUtil.gfn_isNull(this.lv_fatca_check_data.isFlag) ) {
            //   t_popupObj = {
            //     content: 'FATCA(해외금융계좌신고제도) 체크가 정상적으로 종료되지 않았습니다.'
            //   }
            //   this.fn_AlertPopup(0, t_popupObj)
            // }
            return false
          }
        }

        return true
      },

      /***************************************************************************************
        * Function명 : fn_get_invest_uservey_info_slts
        * 설명       : 신탁수탁 시스템 투자성향 설문 데이터 조회
      *****************************************************************************************/
      fn_get_invest_uservey_info_slts(bfcer_cust_no) {	
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570186_S'
        this.eaiCommObj.params = {
          bfcer_cust_no : bfcer_cust_no
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_ts)
      },
      /***************************************************************************************
       * Function명 : fn_call_back_ts
       * 설명       : 투자성향조회 callback
      *****************************************************************************************/
      fn_call_back_ts(response) {
        this.ds_invest_servey_info_slts = response.data
        if ( this.ds_invest_servey_info_slts.qstn_reg_systm_knd_sc_cd === '2' && //설문등록시스템종류
          this.ds_invest_servey_info_slts.ivt_prpns_lst_chg_dtm !== null &&    //투자성향최종변경일시
          this.ds_invest_servey_info_slts.ivt_prpns_lst_chg_dtm !== '' ) {
          
          let t_popupObj = {}
          t_PopupObj.cancel  = false
          t_popupObj.confirm = true
          t_popupObj.content = "해당 고객이 [일반투자자]인 경우 '확인'을, \n\n[전문투자자]인 경우에는 '취소'를 누르세요."
          this.fn_AlertPopup(0, t_popupObj)  

          t_popupObj = {}
          t_PopupObj.cancel  = false
          t_popupObj.confirm = true
          t_PopupObj.confirmFunc = this.fn_create_invest_uservey_info_by_slts()
          t_popupObj.content = "신탁상품 가입시 등록한 설문이 있습니다. 동일한 설문으로 등록하시겠습니까?"
          this.fn_AlertPopup(0, t_popupObj)  
        }	     
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570187_S'
        this.eaiCommObj.params = {
          bfcer_cust_no   : this.lv_ed_cust_no.value
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SY1005_call_back)
      }, 

      /***************************************************************************************
       * Function명 : fn_create_invest_uservey_info_by_slts
       * 설명       : 설문등록
      *****************************************************************************************/
      fn_create_invest_uservey_info_by_slts() {
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570166_S'
        this.eaiCommObj.params = {
          rlnm_cnf_no_encr      : this.ds_invest_servey_info_slts[0].rlnm_cnf_no_encr,
          rlnm_no_knd_cd        : this.ds_invest_servey_info_slts[0].rlnm_no_knd_cd,
          bfcer_cust_no         : this.lv_ed_cust_no.value,
          fnd_cust_nm           : this.ds_invest_servey_info_slts[0].fnd_cust_nm,
          inqry_resp_rslt_val01 : this.ds_invest_servey_info_slts[0].inqry_resp_rslt_val01,
          inqry_resp_rslt_val02 : this.ds_invest_servey_info_slts[0].inqry_resp_rslt_val02,
          inqry_resp_rslt_val03 : this.ds_invest_servey_info_slts[0].inqry_resp_rslt_val03,
          inqry_resp_rslt_val04 : this.ds_invest_servey_info_slts[0].inqry_resp_rslt_val04,
          inqry_resp_rslt_val05 : this.ds_invest_servey_info_slts[0].inqry_resp_rslt_val05,
          inqry_resp_rslt_val06 : this.ds_invest_servey_info_slts[0].inqry_resp_rslt_val06,
          inqry_resp_rslt_val07 : this.ds_invest_servey_info_slts[0].inqry_resp_rslt_val07,
          inqry_resp_rslt_val08 : this.ds_invest_servey_info_slts[0].inqry_resp_rslt_val08,
          inqry_resp_rslt_val09 : this.ds_invest_servey_info_slts[0].inqry_resp_rslt_val09,
          vuln_ivt_peson_yn     : this.ds_invest_servey_info_slts[0].vuln_ivt_peson_yn,
          ivt_cnvs_hop_yn       : this.ds_invest_servey_info_slts[0].ivt_cnvs_hop_yn,
          qstn_expd_yys         : this.ds_invest_servey_info_slts[0].qstn_expd_yys,
          expd_stnd_ymd         : this.ds_invest_servey_info_slts[0].expd_stnd_ymd
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_ti)
      },
      /***************************************************************************************
       * Function명 : fn_call_back_ti
       * 설명       : 설문등록 callback
      *****************************************************************************************/
      fn_call_back_ti(response) {
        console.log('fn_call_back_ti', response);
        let t_popupObj = {content: "정상적으로 등록되었습니다.\n등록된 내용으로 조회합니다."}
        this.fn_AlertPopup(0, t_popupObj)

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570187_S'
        this.eaiCommObj.params = {
          bfcer_cust_no      : this.lv_ed_cust_no.value
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SY1005_call_back)
      },
      
      /**********************************************************************************
       * Function명 : fn_Btn_life_search_onclick(Div01_div_detail_Btn_life_search_onclick)
       * 설명       : 한도조회
       **********************************************************************************/
      fn_Btn_life_search_onclick(){
        console.log('한도조회 fn_Btn_life_search_onclick')
        if(this.inter_useyn != "Y" ) {
            this.join_limit_amt_disabled = false;
            this.$refs['joinLimitAmt'].focus;
            return;
        }
          
        if(FSCommUtil.gfn_length(this.lv_ed_cust_no.value) < 7) {
          let t_popupObj = {content: "고객번호가 입력되지 않았습니다."}
          this.fn_AlertPopup(0, t_popupObj)
          this.$refs['custNo'].focus
          return
        }
            
        this.ds_tax_prime = []
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570251_S'
        this.eaiCommObj.params = {
          mgt_br : this.gDept_cd,
          tax_tp_gb : this.vTax_tp_gb,
          cust_no : this.lv_ed_cust_no.value
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_prime)
      },
      /***************************************************************************************
       * Function명 : fn_search_cust_no(div_search_Me_cust_no_onchar)
       * 설명       : 세금우대 조회 callback
      *****************************************************************************************/
      fn_call_back_prime(response) {
        this.ds_tax_prime = response.data
        if(this.ds_tax_prime.error_msg === '809900')	{
          this.ME_LIMIT = this.$bizUtil.numberWithCommas(Number(this.ds_tax_prime.nuse_lmt_amt)) //세금우대한도잔여액
          this.join_limit_amt_disabled = false
          this.$refs['joinLimitAmt'].focus
        }	
      },
      /***************************************************************************************
       * Function명 : fn_ed_save_term
       * 설명       : 저축기간 입력 - blur 이벤트 안에 focus 넣으면 무한루프 되니까 하면 안됨
      *****************************************************************************************/
      fn_ed_save_term() {
        console.log('fn_ed_save_term...')

        if(FSCommUtil.gfn_length(this.lv_ed_cust_no.value) < 7) {
          let t_popupObj = {content: '고객번호가 입력되지 않았습니다.'}
          this.fn_AlertPopup(0, t_popupObj)
          return
        }

        var t_term = this.save_term === '' ? 0 : this.save_term
        //1-1.적립기간 체크(5년이상 99년이하)
        if ( t_term < 60 ) {
          let t_popupObj = {content: "연금저축의 적립기간은 5년(60월) 이상으로 입력하여 주십시오."}
          this.fn_AlertPopup(0, t_popupObj)
          return
        } else if (t_term > 1188 ) {
          let t_popupObj = {content: "연금저축의 적립기간은 99년(1188월) 이하로 입력하여 주십시오."}
          this.fn_AlertPopup(0, t_popupObj)
          return
        }
              
        //1-2.적립기간 체크(연단위 입력여부. 12의 배수 입력여부)
        var rest1 = t_term%12

        if ( rest1 != 0 ) {
          let t_popupObj = {content: "연금저축의 적립기간은 연단위로 입력하여 주십시오.(12의배수)"}
          this.fn_AlertPopup(0, t_popupObj)
          return false
        }

        this.fn_calc_paym_start_dt()
      },


      /***************************************************************************************
       * Function명 : fn_calc_paym_start_dt(Div01_div_detail_Ed_save_term_onchanged)
       * 설명       : 연금지급개시일 계산 - 저축기간 onchanged
      *****************************************************************************************/
      fn_calc_paym_start_dt(){
        console.log('연금지급개시일계산 fn_calc_paym_start_dt')

        this.ds_pension_paym_start_dt = []

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570252_S'
        this.eaiCommObj.params = {
          cust_no   : this.lv_ed_cust_no.value,
          tax_tp_gb : this.vTax_tp_gb,
          save_term : this.save_term
        }
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_paym_start)
      },
      /***************************************************************************************
       * Function명 : fn_call_back_paym_start
       * 설명       : 연금지급개시일 계산 callback
      *****************************************************************************************/
      fn_call_back_paym_start(response){
        console.log('연금지급일계산response', response)
        this.ds_pension_paym_start_dt = response.data
        if(this.ds_pension_paym_start_dt.error_msg== '809900' )	{
          var start_dt = this.ds_pension_paym_start_dt.paym_start_dt
          // 저장시 필요한 연금지급일 
          this.paym_start_dt = this.ds_pension_paym_start_dt.paym_start_dt
          // 화면상 연금 지급일 
          this.lv_paym_start_dt = FSCommUtil.gfn_dateReplace(2, this.paym_start_dt)
        }
      },
      /***************************************************************************************
       * Function명 : fn_pension_btrans_yn_onitemchanged(Div01_div_detail_Rad_pension_btrans_yn_onitemchanged)
       * 설명       : 연금지급계좌 onitemchanged
      *****************************************************************************************/
      fn_pension_btrans_yn_onitemchanged(){
        if (this.pension_btrans_yn === 'Y') {//약정
          if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) !== 7) {
            let t_popupObj = {content: "고객번호가 입력 되지 않았습니다."}
            this.fn_AlertPopup(0, t_popupObj)
            this.pension_btrans_yn = 'N'
            this.$refs['custNo'].focus()
            return
          }
          this.fn_btrans_acct_list() //약정계좌 조회
        } else { //예수금
          this.pension_btrans_bank_seq = ''
        }		
      },
      /***************************************************************************************
       * Function명 : fn_btrans_acct_list
       * 설명       : 약정계좌조회
      *****************************************************************************************/
      fn_btrans_acct_list() {
        console.log('fn_btrans_acct_list 약정계좌조회 시작')
        var aCustNo = this.lv_ed_cust_no.value
        if (FSCommUtil.gfn_length(aCustNo) != 7) {
          return
        }
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570202_S'
        this.eaiCommObj.params = {
          bfcer_cust_no   : this.lv_ed_cust_no.value,
          tr_code : 'CU1011', //화면ID
          secure_passv : this.password,//핀패드비밀번호
          sup_id : '', // 승인자사번
          aprv_sno : '', //승인업무식별번호
          ars_pswn_yn : '' //ARS비밀번호여부
        }
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_btrans)
      },

      /***************************************************************************************
       * Function명 : fn_call_btrans
       * 설명       : 약정계좌조회 callback
      *****************************************************************************************/
      fn_call_btrans(response){

        this.ds_1011_seq_no = response.data.sy200000_O_00VO
        if ( FSCommUtil.gfn_length(response.data.sy200000_O_00VO) === 0 ) {
          let t_popupObj = {content: '등록된 약정계좌가 없습니다.'}
          this.fn_AlertPopup(0, t_popupObj)
          this.pension_btrans_yn = 'N'
          this.pension_btrans_bank_seq = ''
        } else {
          if (FSCommUtil.gfn_length(response.data.sy200000_O_00VO) > 0) {
            let t_resultList = response.data.sy200000_O_00VO
            this.ds_1011_seq_no = []
            t_resultList.forEach((item, idx) => {
                this.ds_1011_seq_no.push({
                  value : idx,
                  text : item.btrans_acct_no,
                  hiddenValue : item.account_id,
                  account_id : item.account_id,
                  acct_bp_yn : item.acct_bp_yn,
                  bank_acct_nm : item.bank_acct_nm,
                  bank_acct_no : item.bank_acct_no,
                  btrans_acct_no : item.btrans_acct_no,
                  seq_no : item.seq_no,
                  trd_bank_cd : item.trd_bank_cd
                })
              })
            this.pension_btrans_bank_seq = 0
          }
        } 	
      },
      /**********************************************************************************
       * Function명 : fn_Btn_emp_seler_search_onclick(Div01_div_detail_Btn_emp_search_onclick)
       * 설명       : 권유자(0) , 판매직원(1) 찾기 9903
       **********************************************************************************/ 
      fn_Btn_emp_seler_search_onclick(type) {
        this.pPopup321Obj = {type : type, pFund_type : 0}
        this.$refs.popup321.fn_Open()
      },

      /**********************************************************************************
       * Function명 : fn_Btn_emp_search_onclick(Div01_div_detail_Btn_emp_search_onclick)
       * 설명       : 권유자, 판매직원 찾기 콜백 9903
       * type       : 0 - 권유자, 1 - 판매직원
       **********************************************************************************/ 
      fn_Popup_CallBack321(type, pData, pSubId){
        console.log('321callback', pData)
        console.log(' ', pSubId)
        if (this.pPopup321Obj.type == 0) {
          if (FSCommUtil.gfn_isNull(pData)) return	
          this.inv_empno = pData.staf_eno // 임직원사번
          this.inv_empnm = pData.bfcer_eply_nm // 수익증권사원명	
          this.cu1011_userGrpCd = pData.user_auth_grp_cd //수익증권사용자권한그룹코드
          this.$refs['invEmpno'].focus()		

        } else if (this.pPopup321Obj.type == 1) {

          if (FSCommUtil.gfn_isNull(pData)){
            return	
          }
          var vUser_group_cd = pData.user_auth_grp_cd

          if ( vUser_group_cd != '' && vUser_group_cd != null 
              && vUser_group_cd != '82' && vUser_group_cd != '19' 
              && vUser_group_cd != '83' ) { // 판매직원 사용자 그룹 : FA (82) , 영업점 텔러(19), FO(83)
            let t_popupObj = {content: "해당 직원은 판매직원 사용자 그룹이 아닙니다. \n다시 선택해주세요."}
            this.fn_AlertPopup(0, t_popupObj)
            this.inv_seler = ''
            this.inv_selernm = ''
            this.$refs['invSeler'].focus()
            return
          }
          this.inv_seler = pData.staf_eno // 임직원사번
          this.inv_selernm = pData.bfcer_eply_nm // 수익증권사원명	
          this.$refs['invSeler'].focus()	
        }
      },
      
      /**********************************************************************************
       * Function명 : fn_Btn_counsel_search_onclick(Div01_div_detail_Btn_counsel_search_onclick)
       * 설명       : 상담텔러찾기 9905
       **********************************************************************************/
      fn_Btn_counsel_search_onclick(){
        this.$refs.popup312.fn_Open()	
      },
      
      /**********************************************************************************
       * Function명 : fn_Popup_CallBack312
       * 설명       : 상담텔러찾기 콜백 
       **********************************************************************************/
      fn_Popup_CallBack312(type, pData, pSubId){
        console.log('312 callback', pData )
        if (FSCommUtil.gfn_isNull(pData)) return
        this.inv_counsel = pData.staf_eno // 임직원사번
        this.inv_counselnm = pData.bfcer_eply_nm // 수익증권사원명	
        this.$refs['invCounsel'].focus()
      },

      /**********************************************************************************
       * Function명 : fn_Btn_pm_search_onclick(Div01_div_detail_Btn_pm_search_onclick)
       * 설명       : 지역단FP로 변경 9904 - todo.. 312 아님 임시로 붙여놓음 화면 만들어지면 수정필요 
      **********************************************************************************/
      fn_Btn_pm_search_onclick(){
        this.pPopup322Obj = {pFund_type:'0'}
        this.$refs.popup322.fn_Open()	
      },
      fn_Popup_CallBack322(type, pData, pSubId){
        if (FSCommUtil.gfn_isNull(pData)) return	
        this.inv_pm = pData.staf_eno // 임직원사번
        this.inv_pmnm = pData.bfcer_eply_nm // 수익증권사원명	
        this.$refs['invPm'].focus()		
      },
      /**********************************************************************************
       * Function명 : fn_Btn_fp_search_onclick(Div01_div_detail_Btn_fp_search_onclick)
       * 설명       : FP센터찾기 9906
       **********************************************************************************/
      fn_Btn_fp_search_onclick(subId){
        // FO직원 팝업
        this.pPopup323Obj = {
          subId: subId,
          pDsdomname: subId == 'FO' ? '168' : '',
          pFund_type: '0',
          pBfcer_fnd_cd: ''
        }
        this.$refs.popup323.fn_Open()
      },
      fn_Popup_CallBack323(type, pData, pSubId){
        console.log('FO/FP직원 팝업 callback...')
        if ( pSubId == 'FO' ) {
          this.inv_fo   = pData.staf_eno
          this.inv_fonm = pData.bfcer_eply_nm
        } else {
          this.inv_fp   = pData.staf_eno
          this.inv_fpnm = pData.bfcer_eply_nm
        }
      },
      /**********************************************************************************
       * Function명 : fn_btn_dataCreate00_onclick(Div01_btn_dataCreate00_onclick)
       * 설명       : 행추가
       **********************************************************************************/
      fn_btn_dataCreate00_onclick(){
        if(this.ds_fund_list.length >= 2){
          let t_popupObj = {content: '펀드가입은 2건까지만 가능합니다.'}
          this.fn_AlertPopup(0, t_popupObj)
          return
        }
        if(this.lv_ed_cust_no.value === '' || FSCommUtil.gfn_length(this.lv_ed_cust_no.value) === 0){
          FSCommUtil.gfn_validate(this, '고객번호를 입력해 주십시오.')  
          this.lv_ed_cust_no.error = true     
          this.$refs['custNo'].focus()
          return false
        }
        if(FSCommUtil.gfn_length(this.lv_ed_cust_no.value) !== 7){
          FSCommUtil.gfn_validate(this, '고객번호가 형식에 맞지 않습니다.') 
          this.lv_ed_cust_no.error = true        
          this.$refs['custNo'].focus()
          return false
        }
        if(this.inv_empno === '' || FSCommUtil.gfn_length(this.inv_empno) === 0){
          FSCommUtil.gfn_validate(this, '권유자 사번을 입력해 주십시오.')      
          this.inv_empno_error = true 
          this.$refs['invEmpno'].focus()
          return false
        }
        if(FSCommUtil.gfn_length(this.inv_empno) < 5){
          FSCommUtil.gfn_validate(this, '권유자 사번의 형식에 맞지 않습니다.')       
          this.inv_empno_error = true
          this.$refs['invEmpno'].focus()
          return false
        }
        
            
        var rowCount = this.ds_fund_list.length
        console.log('this.ds_fund_list', this.ds_fund_list)
        var rowPosition = rowCount - 1
        console.log('rowPosition', rowPosition)

        if (FSCommUtil.gfn_length(this.ds_fund_list) > 0 ) {
          if(FSCommUtil.gfn_isNull(this.ds_fund_list[rowPosition].fund_cd)) {
            let t_popupObj = {content: '펀드코드를 입력하여 주십시요!'}
            this.fn_AlertPopup(0, t_popupObj)
            return
          }
          
          if(FSCommUtil.gfn_isNull(this.ds_fund_list[rowPosition].buy_req_amt)) {
            let t_popupObj = {content: '가입금액을 입력하여 주십시요!'}
            this.fn_AlertPopup(0, t_popupObj)
            return
          }
          
          if (this.proc_gb === '21' && this.ds_fund_list[rowPosition].buy_req_amt > 0) {
            let t_popupObj = {content: '연금이체신규는 0원 신규만 가능합니다.'}
            this.fn_AlertPopup(0, t_popupObj)
            return              
          }
        }
          
        
            
        if(this.proc_gb === '11' && rowCount > 7){
          let t_popupObj = {content: '일반신규는 1회당 최대 8개 계좌까지 가능합니다.'}
          this.fn_AlertPopup(0, t_popupObj)

        }else if(this.proc_gb === '21' && rowCount >= 1){
          let t_popupObj = {content: '연금이체신규는1회당 최대 1개 계좌까지 가능합니다.'}
          this.fn_AlertPopup(0, t_popupObj)

        }else{
          this.ds_fund_list.push({
            fund_cd : '', //펀드코드
            fund_nm : '', //펀드명
            fund_prop_grade : '', //펀드등급
            fund_prop_grade_nm : '', //펀드등급명
            fund_chk : '', 
            inv_empno : '', 
            inv_empnm : '',
            user_group_cd : '',
            buy_req_amt : '',
            open_minamt : ''
          })
        }	
      },
      /***************************************************************************************
       * Function명 : fn_length_check
       * 설명       : 길이체크
      *****************************************************************************************/
      fn_length_check(obj, aZeroMsg, aLen, aMsg){
        if(FSCommUtil.gfn_length(obj) === 0) {
          let t_popupObj = {content: aZeroMsg}
          this.fn_AlertPopup(0, t_popupObj)
          return false
        } else if(FSCommUtil.gfn_length(obj) < aLen) {
          let t_popupObj = {content: aMsg}
          this.fn_AlertPopup(0, t_popupObj)
          return false
        }
        return true
      },
      /***************************************************************************************
       * Function명 : fn_fund_list_delete_oncellclick(Div01_Gd_fund_list_oncellclick)
       * 설명       : 펀드리스트 그리드 삭제 cell 클릭
      *****************************************************************************************/
      fn_fund_list_delete_oncellclick(idx){
        let lv_vm = this
        let t_popupObj = {
          confirm: true,

          confirmFunc: function() {
            let ds_fund_list_tmp = []
            lv_vm.ds_fund_list.forEach((item, i) => {
              if(i != idx) { //false
                ds_fund_list_tmp.push(item);
              }
            })
            lv_vm.buy_req_amt_sum = Number(lv_vm.buy_req_amt_sum) - Number(lv_vm.ds_fund_list[idx].buy_req_amt)

            lv_vm.ds_fund_list = []
            lv_vm.ds_fund_list = ds_fund_list_tmp
          },
          content: "현재 선택된 행을 삭제 하시겠습니까?"
        }
        this.fn_AlertPopup(0, t_popupObj)   
      },

      /***************************************************************************************
       * Function명 : fn_fund_list_fndCd_oncellclick(Div01_Gd_fund_list_oncellclick, fn_fund_chk)
       * 설명       : 펀드리스트 그리드 펀드코드 cell 클릭 -  펀드정합성 체크
      *****************************************************************************************/
      fn_fund_list_fndCd_oncellclick(idx){
        this.nRow = idx
        this.fn_fund_chk()
      },

      /***************************************************************************************
       * Function명 : fn_fund_chk
       * 설명       : 펀드정합성 체크
      *****************************************************************************************/
      async fn_fund_chk(){

        /*순서변경	
        - 가장먼저  고객투자성향먼저 체크 
        - ..CDSC체크, 공모펀드 등급확인 ..(변경없음)
        - 펀드적합성 ( 고객투자성향 과 펀드등급으로 적합부적합 판단)
        - ..
        */
        // 1. 고객투자성향 체크  (신규거래시는 당일의 투자성향이 존재해야함)
        this.vCust_grade  = ''
        var avail_std_dt = ''	
        this.vPro_investor_yn = ''

        console.log('펀드정합성체크', this.ds_1011_cust_grade)
        if( FSCommUtil.gfn_length(this.ds_1011_cust_grade) > 0 ) {		
          avail_std_dt     = this.ds_1011_cust_grade[0].avail_std_dt          //설문등록일
          this.vCust_grade      = this.ds_1011_cust_grade[0].ivt_prpns_anly_grd_cd //투자성향
          this.vPro_investor_yn = this.ds_1011_cust_grade[0].tlgm_ivt_peson_yn     //전문투자자 여부 추가
        }
        
        //  당일의 투자성향이 존해 하는지 체크 (없으면 #7005로 이동)
        var vChkInvest_servey = await FSFormUtil.gfn_chkInvest_servey(this,this.vCust_grade, avail_std_dt, this.rname_no_gb_chk, this.rname_no_chk, this.cust_nm_chk, this.lv_ed_cust_no.value, this.fn_AlertPopup)
        if (!vChkInvest_servey) {
          return
        }
        //fn_Popup_CallBack310
        this.$refs.popup310.fn_Open()
      },

      /***************************************************************************************
       * Function명 : fn_Popup_CallBack310
       * 설명       : 펀드정보 팝업 콜백 
      *****************************************************************************************/
      fn_Popup_CallBack310(type, pData, pSubId){
        // 펀드 데이터 매핑 후 펀드코드로 조회
        console.log('310 callback ', type)
        console.log('310 callback ', pData)
        console.log('310 callback ', pSubId)
        if (FSCommUtil.gfn_isNull(pData)) return
        
        this.ds_fund_list[this.nRow].fund_cd = pData.bfcer_fnd_cd
        this.ds_fund_list[this.nRow].fund_nm = pData.bfcer_fnd_nm
        

        pData.cdsc_class_sc_cd == null ? '' : pData.cdsc_class_sc_cd
        pData.fnd_clct_sc_cd == null ? '' : pData.fnd_clct_sc_cd
        pData.fnd_grd == null ? '' : pData.fnd_grd
        
        var vFund_cd         = pData.bfcer_fnd_cd
        var vFund_tp3        = pData.fnd_ivt_obj_typ_cd       //수익증권펀드투자대상유형코드 FUND_TP3= '6'
        var vFund_prop_grade = pData.fnd_grd                  //펀드등급
        var vColl_gb         = pData.fnd_clct_sc_cd          //모집구분 (11 공모펀드, 12 사모펀드)
        var vFund_tp6        = pData.bfcer_qlfd_typ_cd        //수익증권유자격유형코드 FUND_TP6 = '3'
        var vCdsc_gb         = pData.cdsc_class_sc_cd         //CDSC클래스구분코드
          
        /* ASR201100735_그룹신규(#1010) 브릿지계좌신규 등 업무보완 (2020.12.22 이승하)
          ㄴ펀드등급 세팅 구문 추가 */
        console.log('펀드등급 ::: ' + pData.fnd_grd)
        this.ds_fund_list[this.nRow].fund_prop_grade = pData.fnd_grd

          // 공모펀드일경우 펀드등급존재 해야 신규가능
        if (pData.fnd_clct_sc_cd === '11') {  
          if (pData.fnd_grd === '') {
            let t_popupObj = {content: "펀드등급이 등록되지 않아 가입 불가한 펀드입니다."}
            this.fn_AlertPopup(0, t_popupObj)
            this.ds_fund_list[this.nRow].fund_cd = ''
            this.ds_fund_list[this.nRow].fund_nm = ''
            this.ds_fund_list[this.nRow].fund_prop_grade_nm = ''
            vFund_cd = ''
          } else {	//사모펀드
            this.ds_fund_list[this.nRow].fund_prop_grade_nm = '적합'
          }
        }
      
        if (FSCommUtil.gfn_length(this.ds_fund_list[this.nRow].fund_cd) !== 0) {
          if ( pData.anut_psb_yn !== 'Y' ) { //연금가능여부
            let t_popupObj = {content: '연금저축으로 가입이 불가능한 펀드 입니다.'}
            this.fn_AlertPopup(0, t_popupObj)
            this.ds_fund_list[this.nRow].fund_cd = ''
            this.ds_fund_list[this.nRow].fund_nm = ''
            this.ds_fund_list[this.nRow].fund_prop_grade_nm = ''
            return
          }
        
          if (pData.new_dl_psb_yn !== 'Y' || pData.fund_rctm_dl_psb_yn !== 'Y') { //신규거래가능여부, 입금거래가능여부
            let t_popupObj = {content: '신규 개설이 불가능한 펀드 입니다.'}
            this.fn_AlertPopup(0, t_popupObj)
            this.ds_fund_list[this.nRow].fund_cd = ''
            this.ds_fund_list[this.nRow].fund_nm = ''
            this.ds_fund_list[this.nRow].fund_prop_grade_nm = ''
            return
          }
        
          var saveTerm = this.save_term == '' ? 0 : this.save_term
          console.log('saveTerm', saveTerm)
          console.log('pData.shrt_sav_prd_mms', pData.shrt_sav_prd_mms)
          if ( Number(saveTerm) < Number(pData.shrt_sav_prd_mms) ) {
            let t_popupObj = {content: '저축기간이 펀드의 최단저축기간 ('+ pData.shrt_sav_prd_mms +'개월) 보다 짧습니다. \n저축기간을 확인하십시오.'}
            this.fn_AlertPopup(0, t_popupObj)
            this.ds_fund_list[this.nRow].fund_cd = ''
            this.ds_fund_list[this.nRow].fund_nm = ''
            this.ds_fund_list[this.nRow].fund_prop_grade_nm = ''
            return
          }
          
          // 권유자격 확인
          this.fn_inv_emp_chk(vFund_cd, vFund_tp6) 	
          
          //vOld_yn = "Y"; vFund_tp6 = "3"; vColl_gb = "11"; //************************************  test용 임시
          //고령자 && 파생상품 && 모집방식이 공모 이면 고령자 유의상품 처리
          if ( this.vOld_yn === 'Y' && (vFund_tp6 === '3' || vFund_tp3 === '6') && vColl_gb === '11') {
            
            var vFnd_cd = pData.bfcer_fnd_cd
            var vChkYn = FSBizUtil.gfn_agedPesonPrdtChk(this, this.lv_ed_cust_no.value, vFnd_cd, 'S', 'CU101100')

            if(vChkYn === 'N') {
            /* ASR201100735_그룹신규(#1010) 브릿지계좌신규 등 업무보완 (2020.12.22 이승하)
              ㄴ고령자 나이 기준 70->65 */
              let t_popupObj = {content: '만 ' + this.stdOldAge + '세 이상 고객대상 투자권유 유의상품 가입건으로 체크리스트 징구 대상자 입니다.'}
              this.fn_AlertPopup(0, t_popupObj)
              this.btn_confirm = true
              
              vChkYn = FSBizUtil.gfn_agedPesonPrdtChk(this, this.lv_ed_cust_no.value, vFnd_cd, 'I', 'CU101100')
            }
            
            if(vChkYn === '' || vChkYn === '3') { //에러 또는 센터장부재
              this.ds_fund_list[this.nRow].fund_cd = ''
              this.ds_fund_list[this.nRow].fund_nm = ''
              /* ASR201100735_그룹신규(#1010) 브릿지계좌신규 등 업무보완 (2020.12.22 이승하)
                ㄴ펀드 적합 초기화 */
              this.ds_fund_list[this.nRow].fund_prop_grade_nm = ''
              return  	    
            }   	    
          }  	 		
            
          //펀드적합성  - 펀드등급 과 투자성향등급 으로 적합, 부적합 체크 	
          if( vFund_prop_grade !== '' ) {		
            //전문투자는 체크 안함
            if(this.vPro_investor_yn === 'Y' ){
              //pass
              this.ds_fund_list[this.nRow].fund_prop_grade_nm = ''
            }else {
              if( this.vCust_grade === 'A' || (this.vCust_grade === 'B' && vFund_prop_grade >= 2) || (this.vCust_grade === 'C' && vFund_prop_grade >  3) ){			
                // A = 전체 적합,         B or 등급이 2이상 =  1 등급 펀드만 부적합 ,         C or 4이상 = 1,2,3 등급 펀드 부적합
                this.ds_fund_list[this.nRow].fund_prop_grade_nm = '적합'
                this.ds_fund_list[this.nRow].fund_chk = '' // ASR210200794_투자상품 판매 프로세스 강화 개발 요청(1) (2021.03.24 이승하)
                console.log('['+this.nRow+'] 적합' );
                
              } else if( this.vCust_grade === '1' ||							
                (this.vCust_grade === '2' && vFund_prop_grade >= 2) ||
                (this.vCust_grade === '3' && vFund_prop_grade >= 3) ||
                (this.vCust_grade === '4' && vFund_prop_grade >= 4) ||
                (this.vCust_grade === '5' && vFund_prop_grade >= 5) ) {
                // 전체 적합			
                this.ds_fund_list[this.nRow].fund_prop_grade_nm = '적합'
                this.ds_fund_list[this.nRow].fund_chk = '' 
                console.log('['+this.nRow+'] 적합' )
                
              } else {
                this.ds_fund_list[this.nRow].fund_prop_grade_nm = '부적합'
                this.ds_fund_list[this.nRow].fund_chk = 'Y' 
                console.log('['+this.nRow+'] 부적합, fund_chk=Y' )
                /* ASR210200794_투자상품 판매 프로세스 강화 개발 요청(1) (2021.03.24 이승하)
                  →부적합 투자자에 대한 상품 판매 불가 */
                this.btn_confirm = true
                let t_popupObj = {content: '부적합투자자는 금융취약자로 분류되어 투자권유가 불가합니다.'}
                this.fn_AlertPopup(0, t_popupObj)

                return
              }
            }
          } 			
          this.ds_fund_list[this.nRow].open_minamt = pData.instl_new_ent_min_amt //신규최소가입금액 세팅 
          console.log('신규최소가입금액세팅 ', this.ds_fund_list)					
        }
          
        /* ASR210200794_투자상품 판매 프로세스 강화 개발 요청(1) (2021.03.24 이승하)
          →펀드 체크 정상 종료되면 등록 버튼 활성화 */
        if (!FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value) && !FSCommUtil.gfn_isNull(this.lv_ed_cust_nm.value)) {
          this.btn_confirm = false
        }
      },

      /***************************************************************************************
       * Function명 : fn_inv_emp_chk
       * 설명       : 권유자의 권유자격여부 체크
      *****************************************************************************************/
      fn_inv_emp_chk(vFund_cd, vFund_tp6) {
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570194_S'
        this.eaiCommObj.params = {
          staf_eno         : this.inv_empno, // 수익증권소득자구분코드, Length : 3
          bfcer_fnd_tp_cd  : vFund_tp6,        
          bfcer_fnd_cd     : vFund_cd,
          bfcer_eply_nm    : this.inv_empnm 
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_invemp)
      },
      /***************************************************************************************
       * Function명 : fn_call_back_invemp
       * 설명       : 권유자의 권유자격여부 체크 callback
      *****************************************************************************************/
      fn_call_back_invemp(response){
        this.ds_invemp_detail = response.data.sy990300_O_00VO
        if(FSCommUtil.gfn_length(this.ds_invemp_detail) > 0) {
          this.vInv_empno  = this.ds_invemp_detail[0].staf_eno
          this.vInv_empnm  = this.ds_invemp_detail[0].bfcer_eply_nm
          this.vUser_Grpcd = this.ds_invemp_detail[0].bfcer_user_auth_grp_cd
          if (FSCommUtil.gfn_length(this.vInv_empno) != 0) {
            this.ds_fund_list[this.nRow].inv_empno = this.vInv_empno
            this.ds_fund_list[this.nRow].inv_empnm = this.vInv_empnm
            this.ds_fund_list[this.nRow].user_group_cd = this.vUser_Grpcd
          }				
        }
        this.nRow = 0
      },
      /***************************************************************************************
       * Function명 : fn_confirm
       * 설명       : 확인 버튼 클릭 처리
      *****************************************************************************************/
      async fn_confirm(){
        if ( FSCommUtil.gfn_isNull(this.agnt_chk_yn.value) ) {
          let t_popupObj = {content: '대리인여부을 선택하십시오.'}
          this.fn_AlertPopup(0, t_popupObj)
          this.agnt_chk_yn.error = true
          return
        }
        
        if (!(this.isMobile && this.isMtrans)) {
          if (this.password != this.password2) {
            let t_popupObj = {content: '비밀번호를 확인하십시오.'}
            this.fn_AlertPopup(0, t_popupObj)
            this.password = ''
            this.password2 = ''
            this.$refs['password'].focus()
            return
          }
        } else {
          if (this.lv_masked_val != this.lv_masked_val2) {
            let t_popupObj = {content: '비밀번호를 확인하십시오.'}
            this.fn_AlertPopup(0, t_popupObj)
            this.password = ''
            this.password2 = ''
            this.lv_isClear = !this.lv_isClear ? true : false 
            this.lv_isClear2 = !this.lv_isClear2 ? true : false 
            this.$refs['password'].focus()
            return
          }
        }
        if (this.CU1011_valueCheck()) {   //화면값 체크        
          // 커버발행 삭제 2023.11.17 공통커버발행삭제 로직 수정
          if (await FSInfoUtil.preCvrNoDel(this)) {
            let t_popupObj = {
              content: '매입  : ' + this.$commonUtil.numberWithCommas(Number(this.buy_req_amt_sum)) + '원',
              cancel : true,
              confirm : true,
              confirmFunc : this.CU1011_Call_Put //등록
            }
            this.fn_AlertPopup(0, t_popupObj)
          }
        }
      },
      /***************************************************************************************
       * Function명 : CU1011_valueCheck
       * 설명       :  화면값 체크
      *****************************************************************************************/
      CU1011_valueCheck(){

        if (this.lv_ed_cust_no.value === '' || FSCommUtil.gfn_length(this.lv_ed_cust_no.value) === 0) {
          FSCommUtil.gfn_validate(this, '고객번호를 입력해 주십시오.')       
          this.$refs['custNo'].focus()
          this.lv_ed_cust_no.error = true
          return false
        }
        if (FSCommUtil.gfn_length(this.lv_ed_cust_no.value) !== 7) {
          FSCommUtil.gfn_validate(this, '고객번호가 형식에 맞지 않습니다.')       
          this.$refs['custNo'].focus()
          this.lv_ed_cust_no.error = true
          return false
        }
        
        if (!(this.isMobile && this.isMtrans)) {

          if (this.password === '' || FSCommUtil.gfn_length(this.password) === 0) {
            FSCommUtil.gfn_validate(this, '비밀번호를 입력해 주십시오.')       
            this.$refs['password'].focus()
            this.password_error = true
            return false
          }
          if (FSCommUtil.gfn_length(this.password) !== 4) {
            FSCommUtil.gfn_validate(this, '비밀번호는 4자리의 숫자로 입력하여 주십시오.')       
            this.$refs['password'].focus()
            this.password_error = true
            return false
          }
          if (FSCommUtil.gfn_length(this.password2) === 0) {
            FSCommUtil.gfn_validate(this, '확인용 비밀번호를 입력하여 주십시오.')       
            this.$refs['password2'].focus()
            this.password2_error = true
            return false
          } else if (this.password2 !== this.password) {
            FSCommUtil.gfn_validate(this, '비밀번호가 일치하지 않습니다.')       
            this.password2 = ''
            this.$refs['password2'].focus()
            this.password2_error = true
            return false
          }

        } else {
          
          if (this.lv_masked_val === '' || FSCommUtil.gfn_length(this.lv_masked_val) === 0) {
            FSCommUtil.gfn_validate(this, '비밀번호를 입력해 주십시오.')       
            this.$refs['password'].focus()
            this.password_error = true
            return false
          }
          if (FSCommUtil.gfn_length(this.lv_masked_val) !== 4) {
            FSCommUtil.gfn_validate(this, '비밀번호는 4자리의 숫자로 입력하여 주십시오.')       
            this.$refs['password'].focus()
            this.password_error = true
            return false
          }
          
          if (FSCommUtil.gfn_length(this.lv_masked_val2) === 0) {
            FSCommUtil.gfn_validate(this, '확인용 비밀번호를 입력하여 주십시오.')       
            this.$refs['password2'].focus()
            this.password2_error = true
            return false
          } else if (this.lv_password_check === false) {
            FSCommUtil.gfn_validate(this, '비밀번호가 일치하지 않습니다.')
            return false
          }   
        }
        //권유자가 필수 입력사항으로 변경
        if (this.inv_empno === '' || FSCommUtil.gfn_length(this.inv_empno) === 0) {
          FSCommUtil.gfn_validate(this, '권유자 사번을 입력해 주십시오.')       
          this.$refs['invEmpno'].focus()
          this.inv_empno_error = true
          return false
        }
        if (FSCommUtil.gfn_length(this.inv_empno) < 5) {
          FSCommUtil.gfn_validate(this, '권유자 사번의 형식에 맞지 않습니다.')       
          this.$refs['invEmpno'].focus()
          this.inv_empno_error = true
          return false
        }
        
        //판매사원 필수 입력사항으로 변경
        if (this.inv_seler === '' || FSCommUtil.gfn_length(this.inv_seler) === 0){
          FSCommUtil.gfn_validate(this, '판매직원 사번을 입력해 주십시오.')       
          this.$refs['invSeler'].focus()
          this.inv_seler_error = true
          return false
        }
        if (FSCommUtil.gfn_length(this.inv_seler) < 5) {
          FSCommUtil.gfn_validate(this, '판매직원 사번의 형식에 맞지 않습니다.')       
          this.$refs['invSeler'].focus()
          this.inv_seler_error = true
          return false
        }

        var strText = '연금저축'

        //1-1.적립기간 체크(5년이상 99년이하)
        if ( Number(FSCommUtil.gfn_nullToEmpty(this.save_term, '0')) < 60 ) {
          FSCommUtil.gfn_validate(this, strText + ' 의 적립기간은 5년(60월) 이상으로 입력하여 주십시오.')  
          this.$refs['saveTerm'].focus()
          this.save_term_error = true
          return false
        } else if ( Number(FSCommUtil.gfn_nullToEmpty(this.save_term, '0'))  > 1188 ) {
          FSCommUtil.gfn_validate(this, strText + ' 의 적립기간은 99년(1188월) 이하로 입력하여 주십시오.') 
          this.$refs['saveTerm'].focus()
          this.save_term_error = true
          return false
        }
                    
        //1-2.적립기간 체크(연단위 입력여부. 12의 배수 입력여부)
        var rest1 = Number(FSCommUtil.gfn_nullToEmpty(this.save_term, '0')) % 12

        if ( rest1 !== 0 ) {
          FSCommUtil.gfn_validate(this, strText + ' 의 적립기간은 연단위로 입력하여 주십시오.(12의배수)')  
          this.$refs['saveTerm'].focus()
          this.save_term_error = true
          return false
        }
        //3.연금지급개시일 체크
        if ( this.pension_paym_start_dt === '' ) {
          FSCommUtil.gfn_validate(this, strText + '연금지급개시일을 입력하여 주십시오.')  
          this.pension_paym_start_dt_error = true
          return false
        }

        if ( FSCommUtil.gfn_nullToEmpty(this.pension_paym_start_dt, '0') - this.gDate <= 0 ) {
          FSCommUtil.gfn_validate(this, '연금지급개시일이 현재일보다 이전 일 수 없습니다.')  
          this.pension_paym_start_dt_error = true
          return false
        }
      
        if ( FSCommUtil.gfn_nullToEmpty((this.pension_paym_start_dt).replaceAll('-', ''), '0') - (this.lv_paym_start_dt).replaceAll('.', '') < 0 ) {
          FSCommUtil.gfn_validate(this, '연금지급개시일이 계산된 연금지급개시일보다 이전 일 수 없습니다.')  
          this.pension_paym_start_dt_error = true
          return false
        }
              
        //4.연금지급주기
        if ( this.pension_paym_cycle === '' ) {
          FSCommUtil.gfn_validate(this, '연금지급주기를 선택하세요.')  
          this.$refs['pensionPaymCycle'].focus()
          this.pension_paym_cycle_error = true
          return false
        }
        if (this.proc_gb === '21' ) {
          if (FSCommUtil.gfn_nullToEmpty(this.join_limit_amt, '0') === 0) {
            FSCommUtil.gfn_validate(this, strText + ' 가입한도금액을 입력하여 주십시요')  
            this.$refs['joinLimitAmt'].focus()
            this.join_limit_amt_error = true
            return false
          } else if ( FSCommUtil.gfn_nullToEmpty(this.join_limit_amt, '0') > 18000000 ) {  // 은행연합회 확인하지 않으므로 강제 확인			                                                
            FSCommUtil.gfn_validate(this, ' 1800만원을 초과 하였습니다. 가입한도금액을 확인하여 주시기 바랍니다.')  
            this.$refs['joinLimitAmt'].focus()
            this.join_limit_amt_error = true
            return false
          }
        } else {
          //5.세제한도금액 체크
          if(FSCommUtil.gfn_nullToEmpty(this.join_limit_amt, '0') == 0) {
              FSCommUtil.gfn_validate(this, strText + ' 가입한도금액을 입력하여 주십시요')  
              this.$refs['joinLimitAmt'].focus()
              this.join_limit_amt_error = true
              return false
          } else {
            if(this.inter_useyn == 'Y' ) {   //연합회 사용여부시만 사용함
              if(FSCommUtil.gfn_isNull(this.ds_tax_prime.rsp_cd) < 1) {
                  FSCommUtil.gfn_validate(this, '은행연합회 한도조회가 이루어지지 않았습니다. 한도조회를 한 후 등록이 가능합니다.')  
                  return false
              }
              console.log('this.ds_tax_prime???', this.ds_tax_prime)
              if(this.ds_tax_prime.rsp_cd == '401') {
                  //console.log('은행연합회 자료없음 401');
              }else{
                if(FSCommUtil.gfn_nullToEmpty(this.ME_LIMIT, '0') == 0 ) {
                  FSCommUtil.gfn_validate(this, '세제한도가 0원입니다. 신규가 불가능합니다.')
                  return false
                }
                if(Number(FSCommUtil.gfn_nullToEmpty(this.join_limit_amt, '0')) > Number(FSCommUtil.gfn_nullToEmpty(this.ME_LIMIT, '0'))) {
                  FSCommUtil.gfn_validate(this, '세금우대 한도금액을 초과 하였습니다. 가입한도금액을 확인하여 주시기 바랍니다.')  
                  this.$refs['joinLimitAmt'].focus()
                  this.join_limit_amt_error = true
                  return false
                }
              }
            }
          }  
        }
                
        if(this.proc_gb == '21') { //연금이체신규
          if(FSCommUtil.gfn_isNull(this.oring_open_dt)){
            FSCommUtil.gfn_validate(this, '최초가입일을 입력하십시오.')
            this.$refs['oringOpenDt'].focus()
            this.oring_open_dt_error = true
            return false
          }  

          // ASR210600032_세제상품 관련 정정 요청  2021.06.22.
          // 신연금 구분 기준일 변경 (20130401 → 20130301)
          if (FSCommUtil.gfn_nullToEmpty(this.oring_open_dt, '0') - Number('20130301') < 0 ) {
            FSCommUtil.gfn_validate(this, '최초가입일이 2013.03.01 이전 이면 그룹신규(#1010), 계좌신규(#1020)에서만 처리가 가능합니다.')
            this.$refs['oringOpenDt'].focus()
            this.oring_open_dt_error = true
            return
          }    
        }        

        var vGrade_chk = 0 //부적합 펀드 존재 유무
        
        // 펀드 리스트 입력사항 체크
        if(this.ds_fund_list.length < 1) {
          FSCommUtil.gfn_validate(this,'펀드는 최소 1건 이상 가입해야 합니다.')
          return false
        } else {  
          for (var i=0; i < this.ds_fund_list.length; i++) {
            // 펀드코드 입력여부 확인
            if(this.ds_fund_list[i].fund_cd == '' || this.ds_fund_list[i].fund_cd == null) {
              FSCommUtil.gfn_validate(this,'펀드코드 입력여부를 확인하십시오.')
              return false
            } 

            // 가입금액과 펀드 신규최소금액 비교
            else if( (Number(this.ds_fund_list[i].buy_req_amt) > 0)  &&
              Number(this.ds_fund_list[i].buy_req_amt) < Number(this.ds_fund_list[i].open_minamt) ){
              FSCommUtil.gfn_validate(this,'가입금액이 펀드의 신규최소금액 ('+this.$commonUtil.numberWithCommas(this.ds_fund_list[i].open_minamt))+'원) 보다 적습니다. \n가입금액을 확인하십시오.';
              return false
            }

            // 가입금액 입력 체크 
            else if(FSCommUtil.gfn_isNull(this.ds_fund_list[i].buy_req_amt) || this.ds_fund_list[i].buy_req_amt == '') {
              FSCommUtil.gfn_validate(this, '가입금액을 입력하여 주십시요!')
              return false
            }
            
            if(this.ds_fund_list[i].fund_chk == 'Y'){
              vGrade_chk++
            } 
          }
            
          if (vGrade_chk > 0) {
            FSCommUtil.gfn_validate(this,'투자자확인서 작성여부를 확인하기 바랍니다.')
          }
        }
        
        return true
      },
      /***************************************************************************************
       * Function명 : CU1011_Call_Put
       * 설명       :  실제 등록 처리
      *****************************************************************************************/
      CU1011_Call_Put(){
        // 확인버튼 Disable
        this.btn_confirm = true
        this.ds_cu1011 = [{
          pension_btrans_bank_seq : '',
          btrans_bank_cd : '',
          account_id : '',
          btrans_acct_nm : '',
          btrans_acct_no : '',
          secure_passv : '',
          secure_new_passv : '',
          secure_encsymm : '',
          user_group_cd : this.cu1011_userGrpCd
        }]
        console.log('this.ds_1011_cust_grade', this.ds_1011_cust_grade)
        var grade_seq = ''
        if (this.ds_1011_cust_grade[0].ivt_prpns_reg_sno !== 0 ) {
          grade_seq = this.ds_1011_cust_grade[0].ivt_prpns_reg_sno
        }
                
        if (this.pension_btrans_yn === 'Y') {    //약정계좌등록 시 계좌정보 셋팅
          var seq_no = this.ds_1011_seq_no[this.pension_btrans_bank_seq].seq_no
          var rowIdx = this.pension_btrans_bank_seq
          this.ds_cu1011[0].pension_btrans_bank_seq = seq_no
          this.ds_cu1011[0].btrans_bank_cd = this.ds_1011_seq_no[rowIdx].trd_bank_cd
          this.ds_cu1011[0].account_id = this.ds_1011_seq_no[rowIdx].account_id
          this.ds_cu1011[0].btrans_acct_nm = this.ds_1011_seq_no[rowIdx].bank_acct_nm
          this.ds_cu1011[0].btrans_acct_no = this.ds_1011_seq_no[rowIdx].bank_acct_no
        } else {
          this.ds_cu1011[0].pension_btrans_bank_seq = '0'
        }
        
        var secure_passv = this.password
        var secure_passv2 = this.password2
        this.ds_cu1011[0].secure_passv = secure_passv
        this.ds_cu1011[0].secure_new_passv = secure_passv2
        this.ds_cu1011[0].secure_encsymm = ''
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'I'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570253_S'
        this.eaiCommObj.params = {
          account_id : this.ds_cu1011[0].account_id, // 계좌id, Length : 40
          btrans_acct_nm : this.ds_cu1011[0].btrans_acct_nm, // 계좌명, Length : 150
          btrans_acct_no : this.ds_cu1011[0].btrans_acct_no, // 계좌번호, Length : 44
          btrans_bank_cd : this.ds_cu1011[0].btrans_bank_cd, // 은행코드, Length : 3
          btrans_gb : this.btrans_gb, // 자동이체신청여부, Length : 1
          check_qty : '', // 수표매수, Length : 13
          check_recp_amt : '', // 수표입금액, Length : 25
          currency_cd : '', // 통화코드, Length : 10
          cust_nm : this.lv_ed_cust_nm.value, // 고객명, Length : 150
          cust_no : this.lv_ed_cust_no.value, // 고객번호, Length : 7
          depo_fund_cd : '100000', // 예수금펀드코드, Length : 12
          grade_seq : grade_seq, // 투자성향설문 일련번호, Length : 40
          inv_counsel : this.inv_counsel, // 상담텔러, Length : 10
          inv_empno : this.inv_empno, // 권유자, Length : 30
          inv_fo : this.inv_fo, // fo, Length : 20
          inv_fp : this.inv_fp, // fp, Length : 10
          inv_pm : this.inv_pm, // pm, Length : 10
          inv_seler : this.inv_seler, // 판매사원번호, Length : 40
          join_limit_amt : this.join_limit_amt, // 세제한도금액, Length : 25
          link_acct_no : '', // 가상계좌번호, Length : 20
          link_acct_yn : 'Y', // 가상계좌 등록여부, Length : 1
          link_bank_cd : '', // 가상계좌은행코드, Length : 3
          mgt_br : this.gDept_cd,//this.ds_cu1011[0].mgt_br, // 관리기관, Length : 8
          new_proc_gb : this.proc_gb, // 신규구분, Length : 4
          ob_yn : 'N', // OutBound 여부, Length : 1
          order_type : this.order_type, // 매수구분, Length : 4
          oring_open_dt : (this.oring_open_dt).replaceAll('-', ''), // 최초가입일, Length : 8
          pbk_no : '', // 그룹번호, Length : 10
          pension_btrans_bank_seq : this.ds_cu1011[0].pension_btrans_bank_seq, // 연금지급계좌 Seq, Length : 20
          pension_btrans_yn : this.pension_btrans_yn, // 연금지급계좌여부, Length : 1
          pension_paym_cycle : this.pension_paym_cycle, // 연금지급주기, Length : 5
          pension_paym_open_dt : (this.pension_paym_start_dt).replaceAll('-', ''), // 연금지급개시일, Length : 8
          pension_paym_term : this.pension_paym_term, // 연금지급기간, Length : 8
          pers_corp_gb : '', // 개인법인구분, Length : 4
          recp_cash_amt : '', // 현금입금액, Length : 21
          recp_resv_req_amt : '', // 본사입금액, Length : 25
          reinv_yn : this.reinv_yn, // 재투자여부, Length : 1
          save_term : this.save_term, // 저축기간, Length : 9
          save_tp : this.save_tp, // 저축종류, Length : 6
          send_msg : '', // 전문메시지, Length : 1000
          tax_gb : this.TAX_GB, // 과세구분, Length : 2
          tax_tp_gb : this.vTax_tp_gb, // 세제유형구분, Length : 1
          term_dept : this.gDept_cd, // 처리부서, Length : 40
          total_recp_amt : '', // 총입금액, Length : 25
          user_group_cd : FSCommUtil.gfn_isNull_replace(this.ds_cu1011[0].user_group_cd, ''), // 권유자 구분, Length : 4
          win_alt_recp_amt : '', // 대체입금액, Length : 25
          payr_no : '', //납부자번호
          secure_passv : secure_passv, //비밀번호
          secure_new_passv : secure_passv2, //신규비밀번호 
          secure_encsymm : '', //ENCSYMM
          tel_use_yn : 'N', //유선사용여부
          sup_id : '', //sup_id
          aprv_sno : '0', 
          CU101100_I_01VO : this.ds_fund_list,
          secure_encsymm : 'SEED'
        }
        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back)
      },

      /***************************************************************************************
       * Function명 : fn_call_back
       * 설명       : 연금계좌신규개설등록 callback
      *****************************************************************************************/
      fn_call_back(response) {
        console.log('연금계좌신규개설등록 callback', response)

        if (response.data.error_msg === '809900' || response.data.error_msg === '809901') {

          if (FSCommUtil.gfn_length(FSCommUtil.gfn_trim(response.data.message)) > 0) {
            let t_popupObj = {content: FSCommUtil.gfn_trim(response.data.message)}
            this.fn_AlertPopup(0, t_popupObj)
          }
          
          if (FSCommUtil.gfn_length(FSCommUtil.gfn_trim(response.data.send_msg)) > 0) {
            let t_popupObj = {content: FSCommUtil.gfn_trim(response.data.send_msg)}
            this.fn_AlertPopup(0, t_popupObj)
          }
          
          this.btn_confirm = true
          //신규계좌번호 저장
          this.lv_bfcer_acct_no = response.data.bfcer_accn_no
          var t_accn_no_msg = this.lv_ed_cust_nm.value + '님 신규그룹번호는'
          var t_accn_no_msg2 = response.data.pbk_no.substr(0, 7) + '-' + response.data.pbk_no.substr(7,3) + ' 입니다.'
          //서식/장표 report 출력
          this.lv_reportData = {
            rprt_frm_no          : response.data.rprt_frm_no,
            bfcer_busn_trt_id    : response.data.bfcer_busn_trt_id,
            bfcer_busn_trt_sc_cd : response.data.bfcer_busn_trt_sc_cd,
            bfcer_cust_no        : response.data.bfcer_cust_no,
            frm_issnc_ymd        : response.data.frm_issnc_ymd,
            issnc_hstr_sno       : response.data.issnc_hstr_sno,
            bfcer_accn_no        : response.data.bfcer_accn_no
          }

          let lv_vm = this
          let t_type = 0          
          let t_procTyp = lv_vm.getStore('fsStore').getters.getBasInfo.data.procTyp
          let t_popupObj = {}

          if( this.lv_isProcess ) { // 계좌신규/그룹신규 프로세스 진행 화면 (전자서식 호출 후 store 담고 다음화면 이동)
            if(this.proc_gb == '21') { //연금이체신규
              t_type = 7
              var paramObj = {}
              paramObj.rmvl_srn_id = 'CU101100'
              t_popupObj = {
                cancel: false,
                confirm: true,
                confirmFunc: function() {
                  lv_vm.fn_ReportPrint()
                  lv_vm.$router.push({
                    name: 'MSPFS304M',                     
                    params: {
                      rmvl_srn_id: 'CU101100',
                      cust_no: FSCommUtil.gfn_substr(response.data.bfcer_accn_no, 0, 10),
                      acct_gb: FSCommUtil.gfn_substr(response.data.bfcer_accn_no, 10, 3)
                    }
                  })
                }, 
                contentTitle: '연금계좌신규개설 완료',
                content: [
                  response.data.message,
                  t_accn_no_msg,
                  t_accn_no_msg2,
                  '연금이전신청 화면으로 이동합니다.'
                ]
              }

            } else { // 일반신규일때 
              if ( t_procTyp == 'grpNew' ){ // 그룹신규 프로세스 일때
                t_type = 7
                var paramObj = {}
                paramObj.rmvl_srn_id = 'CU101100'
                t_popupObj = {
                  cancel: false,
                  confirm: true,
                  confirmFunc: function() {
                    lv_vm.fn_ReportPrint()
                    lv_vm.$router.push({
                      name: 'MSPFS109M',                     
                      params: {
                        rmvl_srn_id: 'CU101100',
                        bfcer_cust_no: FSCommUtil.gfn_substr(response.data.bfcer_accn_no, 0, 7)
                      }
                    })
                  }, 
                  contentTitle: '연금계좌신규개설 완료',
                  content: [
                    response.data.message,
                    t_accn_no_msg,
                    t_accn_no_msg2,
                    '',
                    '인터넷펀드거래서비스신청 화면으로 이동합니다.'
                  ]
                }
              } else { // 계좌신규 프로세스일때 일반신규 선택 시 '연금계좌신규개설'이 마지막 화면

                t_type = 9
                this.getStore('fsStore').dispatch('IS_LAST_START')
                t_popupObj = {
                  cancel: true,
                  confirm: true,
                  confirmFunc :lv_vm.fn_ReportPrint,
                  confirmData: {
                    bfcer_cust_no : lv_vm.lv_ed_cust_no.value
                  },
                  contentTitle: '연금계좌신규개설 완료',
                  content: [
                    response.data.message,
                    t_accn_no_msg,
                    t_accn_no_msg2
                  ]
                }
              }
            }
            this.fn_AlertPopup(t_type, t_popupObj)
          } else { // 계좌신규/그룹신규 프로세스 마지막 화면 (본인인증 후 전자서식 후 종료)
            t_popupObj = {
              content: ['연금계좌신규개설이 완료되었습니다.',t_accn_no_msg,t_accn_no_msg2]
            }
          }
          this.lv_btn_next = false
        } else {
          let t_popupObj = {content: FSMessageUtil.commMessage([])[response.data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)
          this.btn_confirm = false
        }
      },
      /***************************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 서식/장표 report  data 조회  완료후 //todo..
      *****************************************************************************************/
      async fn_ReportPrint(nextFunc){	
        console.log('fn_ReportPrint....')
        let formList = []
        let fndGrdList = [
          {fnd_grd : 1, fnd_grd_nm : '매우 높은 위험'},
          {fnd_grd : 2, fnd_grd_nm : '높은 위험'},
          {fnd_grd : 3, fnd_grd_nm : '다소 높은 위험'},
          {fnd_grd : 4, fnd_grd_nm : '보통 위험'},
          {fnd_grd : 5, fnd_grd_nm : '낮은 위험'},
          {fnd_grd : 6, fnd_grd_nm : '매우 낮은 위험'}
        ]
        
        let searchData = {
          rprt_frm_no          : this.lv_reportData.rprt_frm_no,
          bfcer_busn_trt_id    : this.lv_reportData.bfcer_busn_trt_id,
          bfcer_busn_trt_sc_cd : this.lv_reportData.bfcer_busn_trt_sc_cd,
          bfcer_cust_no        : this.lv_reportData.bfcer_cust_no,
          frm_issnc_ymd        : this.lv_reportData.frm_issnc_ymd,
          issnc_hstr_sno       : FSCommUtil.gfn_trim(this.lv_reportData.issnc_hstr_sno),
          bfcer_fnd_cd         : this.ds_fund_list[0].fund_cd,
          bfcer_accn_no        : this.lv_bfcer_acct_no
        }

        console.log('리포트 호출 ', searchData)

        let FS000019 = {formId: 'FS000019'} // 연금계좌 신규거래신청서
        let FS000000 = {formId: 'FS000000'} // 전자서명 동의서
        let FS000011 = {formId: 'FS000011'} // 투자상품판매체크리스트
        let FS000035 = {formId: 'FS000035'} // 수익증권저축약관
        let FS000036 = {formId: 'FS000036'} // 연금저축계좌설정약관
        let FS000029 = {formId: 'FS000029', isMulti: true}
        let FS000030 = {formId: 'FS000030', isMulti: true}
        let FS000031 = {formId: 'FS000031', isMulti: true}
        let FS000032 = {formId: 'FS000032', isMulti: true}
        let FS000033 = {formId: 'FS000033', isMulti: true}
        let FS000034 = {formId: 'FS000034', isMulti: true}

        let params = {
          reportParam: JSON.stringify(searchData),
          reportUrl: 'reportCO070500_Info',
        }

        var fnd_grd = ''
        var fnd_grd_nm = ''

        if(this.ds_fund_list.length > 1){
          fnd_grd    = fndGrdList[Number(this.ds_fund_list[0].fund_prop_grade) - 1].fnd_grd +  ', ' +  fndGrdList[Number(this.ds_fund_list[1].fund_prop_grade) - 1].fnd_grd
          fnd_grd_nm = fndGrdList[Number(this.ds_fund_list[0].fund_prop_grade) - 1].fnd_grd_nm +  ', '+ fndGrdList[Number(this.ds_fund_list[1].fund_prop_grade) - 1].fnd_grd_nm
        }else{
          fnd_grd    = fndGrdList[Number(this.ds_fund_list[0].fund_prop_grade) - 1].fnd_grd
          fnd_grd_nm = fndGrdList[Number(this.ds_fund_list[0].fund_prop_grade) - 1].fnd_grd_nm
        }

        FS000019.mappingData = {
          fnd_grd    : fnd_grd,
          fnd_grd_nm : fnd_grd_nm,
          inv_empno  : this.inv_empnm + this.inv_empno
        }

        console.log('전자서식 파라미터 000019', searchData)
        FS000019.params = params
        formList.push(FS000019)

        // 핵심설명서 및 확인서
        console.log('핵심설명서 펀드리스트', this.ds_fund_list)
        for ( let i = 0; i < this.ds_fund_list.length; i++ ) {
          if( this.ds_fund_list[i].fund_prop_grade === '1' ) {
            FS000029.mappingData = {
              tstPrdtNm: this.ds_fund_list[i].fund_nm
            }
            formList.push(Object.assign({},FS000029))
          }

          if( this.ds_fund_list[i].fund_prop_grade == '2' ) {
            FS000030.mappingData = {
              tstPrdtNm: this.ds_fund_list[i].fund_nm
            }
            formList.push(Object.assign({},FS000030))
          }

          if( this.ds_fund_list[i].fund_prop_grade == '3' ) {
            FS000031.mappingData = {
              tstPrdtNm: this.ds_fund_list[i].fund_nm
            }
            formList.push(Object.assign({},FS000031))       
          }

          if( this.ds_fund_list[i].fund_prop_grade == '4' ) {
            FS000032.mappingData = {
              tstPrdtNm: this.ds_fund_list[i].fund_nm
            }
            formList.push(Object.assign({},FS000032))          
          }

          if( this.ds_fund_list[i].fund_prop_grade == '5' ) {
            FS000033.mappingData = {
              tstPrdtNm: this.ds_fund_list[i].fund_nm
            }
            formList.push(Object.assign({},FS000033))
          }

          if( this.ds_fund_list[i].fund_prop_grade == '6' ) {
            FS000034.mappingData = {
              tstPrdtNm: this.ds_fund_list[i].fund_nm
            }
            formList.push(Object.assign({},FS000034))
          }

        }
        
        var t_fndNm = this.ds_fund_list[0].fund_nm 
        if(this.ds_fund_list.length > 1){
          t_fndNm = t_fndNm +  ', ' +this.ds_fund_list[1].fund_nm
        }
        FS000000.mappingData = { 
          fund_nm: t_fndNm,
          bfcer_cust_no : this.lv_reportData.bfcer_cust_no
        }
        formList.push(FS000000)

        FS000011.mappingData = {
          plzaNm: this.lv_basInfo.brNm, // 플라자명  
          
        }
        formList.push(FS000011)
        formList.push(FS000035)
        formList.push(FS000036)
        
        console.log('303M formList', formList);
        FSInfoUtil.commReportInfo(this, formList, nextFunc)
      },
      /**************************************************************************************************************
      * Function명 : fn_sum_buy_req_amt
      * 설명       : 합계 계산 
      ***************************************************************************************************************/
      fn_sum_buy_req_amt() {
        this.buy_req_amt_sum = 0
        this.ds_fund_list.forEach((item) => {
          this.buy_req_amt_sum = Number(item.buy_req_amt) + Number(this.buy_req_amt_sum)
        })
      },
      /**************************************************************************************************************
      * Function명 : fn_moveMenuId
      * 설명       : 메뉴 이동 
      ***************************************************************************************************************/
      fn_moveMenuId(menuId, params) {
        this.$router.push({
          name: menuId, 
          params: params
        })
      },
      /***************************************************************************************
       * Function명 : fn_popup301, fn_Popup_CallBack301
       * 설명       : AML실제소유자여부 
      *****************************************************************************************/
      fn_popup301() {
        // AML_실제소유자여부확인
        this.$refs.popup301.fn_Open()
      },

      fn_Popup_CallBack301(type, pData, pSubId) {
        console.log('AML실제소유자여부 callback...')

        // 고객번호 매핑 후 조회
        this.lv_reObjRtn = pData
        console.log(this.lv_reObjRtn)
        
        this.lv_ed_cust_no.value = pData.cust_no
        this.fn_SearchValid({key: 'v'}, 0)
      },

      /***************************************************************************************
       * Function명 : fn_proc_gb_onitemchanged(div_search_Rad_proc_gb_onitemchanged)
       * 설명       :  신규구분 변경
      *****************************************************************************************/
      fn_proc_gb_onitemchanged() {
        if(this.proc_gb === '21') { //연금이체신규
          this.cal02 = false
          let lv_Vm = this
          let t_popupObj = {
            content: '세제상품전금융기관등록조회(#1555) 화면에서 최초가입일 정보를 확인하세요.',
            confirm: true,
            confirmFunc: function() {
              lv_Vm.pPopup309Obj = {}
              lv_Vm.pPopup309Obj.rmvl_srn_id = 'CU101100'
              lv_Vm.pPopup309Obj.rlnm_cnf_no = lv_Vm.rname_no_chk
              lv_Vm.pPopup309Obj.fnd_cust_nm = lv_Vm.cust_nm_chk

              console.log('rlnm_cnf_no=' + lv_Vm.rname_no_chk)
              console.log('fnd_cust_nm=' + lv_Vm.cust_nm_chk)	

              lv_Vm.$refs.popup309.fn_openModal()	 
            },
          }
          this.fn_AlertPopup(0, t_popupObj)

        }else {
          this.oring_open_dt = ''
          this.cal02 = true
        }
      },

      /**********************************************************************************
       * Function명 : fn_Popup_CallBack309
       * 설명       : 세제상품전금융기관 콜백 
       **********************************************************************************/
      fn_Popup_CallBack309(type, pData, pSubId){
        console.log('309popup callback --->', pData);
      },

      /**********************************************************************************
       * Function명 : fn_Popup_CallBack303
       * 설명       : FATCA개인 콜백 
       **********************************************************************************/
      fn_Popup_CallBack303(type, pData, pSubId){
        // 2024.01.17 ASR240100575 FATCA팝업 안닫힘 수정
        if (type === 61) {
          this.$refs.fsHeader.fn_GoHome()
        } else {
          console.log('FATCA개인 callback...')
          // 고객번호 매핑 후 조회
          this.lv_ed_cust_no.value = pData.cust_no
          this.fn_SearchValid({key: 'v'}, 0)
        }
      },

      /**********************************************************************************
       * Function명 : fn_Popup_CallBack305
       * 설명       : 
       **********************************************************************************/
      fn_Popup_CallBack305(type, pData, pSubId){
        console.log('고위험승인요청 callback...')

        // 고객번호 매핑 후 조회
        // ASR240600395 고위험승인내역 화면 개선 요청 
        this.lv_ed_cust_no.value = pData.acct_no

        this.fn_SearchValid({key: 'v'}, 0)
      },

      /**********************************************************************************
       * Function명 : fn_Popup_CallBack313
       * 설명       : 
       **********************************************************************************/
      fn_Popup_CallBack313(type, pData, pSubId){
        console.log('업무대리인 조회', pData)
      },

      /**********************************************************************************
       * Function명 : fn_Popup_CallBack315
       * 설명       : 
       **********************************************************************************/
      fn_Popup_CallBack315(type, pData, pSubId){
        console.log('자금세탁(AML) 고객확인 재이행 callback...')

        // 고객번호 매핑 후 조회
        this.lv_reObjRtn = pData
        console.log(this.lv_reObjRtn)
        this.lv_ed_cust_no.value = pData.cust_no
        this.lv_reCall_yn = 'Y'

        this.fn_SearchValid({key: 'v'}, 0)
      },
      
      /******************************************************************************
       * Function명 : fn_GoNextPage
       * 설명       : 고객정보등록 페이지로 이동
       ******************************************************************************/
      fn_GoNextPage(gb) {
        if(gb === 'MSPFS304M'){
          this.$router.push({
            name: 'MSPFS304M', 
            params: {
              rmvl_srn_id: 'CU101100',
              accn_no: this.lv_bfcer_acct_no
            }
          })
        }else if(gb === 'MSPFS109M'){
          this.$router.push({
            name: 'MSPFS109M',                     
            params: {
              rmvl_srn_id: 'CU101100',
              bfcer_cust_no: this.lv_ed_cust_no.value
            }
          })
        }
      },

      /******************************************************************************
       * Function명 : fn_agntSelect
       * 설명       : 대리인 조회 
       ******************************************************************************/
      fn_agntSelect(){
        this.agnt_chk_yn.error = false
        if (this.agnt_chk_yn.value == 'N') {
          return
        }
        /* 계좌번호 조회 여부 및 선택  확인 */
        if(this.lv_ed_cust_no.value == '' || FSCommUtil.gfn_isNull(this.lv_ed_cust_no.value)) {
          FSCommUtil.gfn_validate(this, '고객번호가 입력되지 않았습니다.')
          this.lv_ed_cust_no.error = true
          this.$refs['custNo'].focus()
          this.agnt_chk_yn.value = ''
          return
        } else if( FSCommUtil.gfn_length(this.lv_ed_cust_no.value) != 7 ) {
          FSCommUtil.gfn_validate(this, '고객번호 형식에 맞지 않습니다.')
          this.lv_ed_cust_no.error = true
          this.$refs['custNo'].focus()
          this.agnt_chk_yn.value = ''
          return
        }

        let t_params = {
          bfcer_cust_no : this.lv_ed_cust_no.value,
          tr_code : 'CU101100',
          menu_id : 'MSPFS303M',
          btn_id: 'agnt_chk_yn'
        }
        FSInfoUtil.agentCheck(this, t_params, this.fn_AlertPopup)
      },

      /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type)
          0: 고객정보
          1: 펀드정보
          MSPFS105P: 상담텔러 팝업
          MSPFS301P: AML_실제소유자여부확인
          MSPFS303P: FATCA개인
          MSPFS305P: 고위험승인요청
          MSPFS309P: 세제상품전금융기관등록조회
          MSPFS313P: 업무대리인 조회
          MSPFS315P: 자금세탁(AML) 고객확인 재이행
          MSPFS321P: 직원검색 팝업
          MSPFS322P: 지역단FP 팝업
          MSPFS323P: FO/FP직원 팝업
       ******************************************************************************/
      fn_OpenPopup(type, pData, subId) {
        let t_popupObj = {}

        switch (type) {
          case 'MSPFS301P':
            // AML_실제소유자여부확인
            this.pPopup301Obj = pData
            this.$refs.popup301.fn_Open()
            break

          case 'MSPFS303P':
            // FATCA개인
            this.pPopup303Obj = pData
            this.$refs.popup303.fn_Open()
            break

          case 'MSPFS305P':
            // 고위험승인요청
            this.pPopup305Obj = pData
            this.$refs.popup305.fn_Open()
            break

          case 'MSPFS309P':
            // 세제상품전금융기관등록조회
            this.pPopup309Obj = pData
            this.$refs.popup309.fn_openModal()
            break
            
          case 'MSPFS313P':
            // 업무대리인 조회 팝업
            this.pPopup313Obj = pData
            this.$refs.popup313.fn_Open()
            break

          case 'MSPFS315P':
            // 자금세탁(AML) 고객확인 재이행
            this.pPopup315Obj = pData
            this.$refs.popup315.fn_Open()
            break
        }
      },

      /******************************************************************************
       * Function명 : fn_agntSelect_callBack
       * 설명       : 대리인 조회 콜백  
       ******************************************************************************/
      fn_agntSelect_callBack(response) {
        //데이터가 존재하지 않을 경우 
        console.log('대리인 조회 response', response)
        if (response == undefined || FSCommUtil.gfn_isEmpty(response.data)) {
          console.log('대리인 조회 결과 = 없음')
          this.agnt_chk_yn.value = 'N'
          return
        }
        var vAgnt_proc_gb = response.data.agnt_prov_gb
        if ( vAgnt_proc_gb === '0') {
          //만기일 정상
          let t_popupObj = {
            cancel: false,
            confirm: true,
            confirmFunc: this.fn_OpenPopup(0, this.lv_ed_cust_no.value),
            content: '대리인 체크정상! 대리인 정보 조회하시겠습니까?'}
          this.fn_AlertPopup(0, t_popupObj)

        } else if ( vAgnt_proc_gb === '1') {
          //만기일 경과
          // [확인]버튼 클릭시 #4100화면 이동(가능한 고객번호  자동입력)
          let t_popupObj = {
            cancel: false,
            confirm: true,
            confirmFunc: this.fn_GoAgentRegist(),
            content: '대리인지정 만기일이 경과하였습니다. 기간을 연장하시기 바랍니다'}
          this.fn_AlertPopup(0, t_popupObj)
          this.agnt_chk_yn.value = 'N'
                
        } else if ( vAgnt_proc_gb === '9'){    
          //만기일 정보 없음
          //[확인]버튼 클릭  : #4100화면으로  이동(가능한 고객번호 자동입력)
          let t_popupObj = {
            cancel: false,
            confirm: true,
            confirmFunc: this.fn_GoAgentRegist(),
            content: '대리인은 해당 업무에 대하여 위임한 내용이 없습니다.대리인 등록 바랍니다.'}
          this.fn_AlertPopup(0, t_popupObj)
          this.agnt_chk_yn.value = 'N'
        }
      },
      
      /******************************************************************************
       * Function명 : fn_GoAgentRegist
       * 설명       : 업무대리인등록/변경
       ******************************************************************************/
      fn_GoAgentRegist() {
        this.$router.push({
          name: 'MSPFS302M', 
          params: {
            menu_id: 'MSPFS303M',
            bfcer_cust_no: this.lv_ed_cust_no.value,
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = [
          {'cId':'350', 'dayChkYn':'N'},
          {'cId':'269', 'dayChkYn':'N'},
          {'cId':'989', 'dayChkYn':'N'}
        ]
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },

      fn_SetCommCodeResult(pResult) {
        this.lv_commCode = pResult
      },
      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
          this.pAlertPopupObj.content = pPopupObj.content          
          this.pAlertPopupObj.confirm = false
          this.pAlertPopupObj.cancel = true
          this.pAlertPopupObj.type = 0
          this.pAlertPopupObj.contentTitle = ''
          this.pAlertPopupObj.btnType = 0

          if( !pPopupObj.cancel ) {
            this.pAlertPopupObj.cancel = pPopupObj.cancel
          }
          if( pPopupObj.confirm ) {
            this.pAlertPopupObj.confirm = pPopupObj.confirm
            this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            this.pAlertPopupObj.confirmData = pPopupObj.confirmData
          }
          if( pPopupObj.closeFunc ) {
            this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
            this.pAlertPopupObj.closeData = pPopupObj.closeData
          }
          if( !FSCommUtil.gfn_isNull(type) && type != 0 ) {
            this.pAlertPopupObj.type = type
          }
          if( pPopupObj.contentTitle ) {
            this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle
          }
          if( pPopupObj.btnType ) {
            this.pAlertPopupObj.btnType = pPopupObj.btnType
          }          
        }
        this.$refs.alertPopup.fn_Open()
      },

      /******************************************************************************
       * Function명 : fn_CustInfo, fn_CustInfoResult
       * 설명       : 고객정보조회
       ******************************************************************************/
      fn_CustInfo() {
        console.log('fn_CustInfo.....')

        let t_data = {bfcer_cust_no : this.lv_ed_cust_no.value}
        FSInfoUtil.commInfo(this, 1, t_data, this.fn_CustInfoResult)
      },

      fn_CustInfoResult(pResultData) {
        console.log('fn_CustInfoResult.....', pResultData)

        let t_data = pResultData.data.sy100500_O_00VO
        console.log(t_data)

        if( t_data.length > 0 ) {
          // 고객명
          this.lv_ed_cust_nm.value = t_data[0].fnd_cust_nm

          // 고객번호 설정 (*이탈 시 커버발행 설정 목적)
          // this.$bizUtil.fsUtils.setCustNoForCvrBpl(this, t_data[0].fnd_cust_nm, this.lv_ed_cust_no.value)
          // 고객용 정보조회
          this.$bizUtil.fsUtils.selCustInfo(this, {bfcer_cust_no: this.lv_ed_cust_no.value})

          // 접근제한 고객일 경우 STOP?
          if( FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_ed_cust_nm.value)) ){
            let t_popupObj = {content: '해당 고객은 접근제한 고객입니다.'}
            this.fn_AlertPopup(0, t_popupObj)
            return
          }

          // 조회/확인 버튼 활성화
          this.btn_confirm = false

          // 통합계좌여부와 매입가능 금액 조회
          // this.fn_BaseInfoCallBack()

        } else {

          // 조회결과 없는 경우
          this.lv_ed_cust_no.value = ''
          this.lv_ed_cust_nm.value = ''
        }

        this.fn_baseInfo();
      },
      fn_Cust316p(custInfo) {
        this.pCust316Obj.cust_no = custInfo.cust_no
        this.pCust316Obj.cust_nm = custInfo.cust_nm
        this.$refs.popup316.fn_Open()
      },
      fn_reinvCheck(){
        console.log('재투자여부 =====', this.reinv_yn)
      },
      fn_save_tp(){
        console.log('저축종류  =====', this.save_tp)
      },
      openModal(type) {
        for(let i=1; i<101; i++) {
          this["modal"+type].open()
          break
        }
      },
      closeModal(type) {
        for(let i=1; i<101; i++) {
          this["modal"+type].close()
          break
        }
      },
    }
  };
</script>
<style scoped>
</style>